import { alpha } from '@mui/material/styles';
import theme from 'containers/App/theme';

export const cartItemsContainerStyling = {
  backgroundColor: '#fff',
  borderRadius: 16,
  padding: 0,
  position: 'relative',
  maxHeight: 564,
  overflow: 'auto',
  width: '100%',
  '& .MuiListItem-root': {
    margin: theme.spacing(0, 1.5),
    width: 'calc(100% - 24px)',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  '& .item-amount': {
    fontWeight: '700 !important',
  },
  '& .no-results': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    padding: theme.spacing(2),
  },
};

export const bigProgressStyling = {
  alignItems: 'center',
  backgroundColor: alpha('#fff', 0.5),
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 500,
};
