import React, { useEffect, useState } from 'react';
import OpenAppPage from 'components/WithAndroidRedirect/OpenAppPage/OpenAppPage';
import LoadingPage from 'routes/LoadingPage';
import { isOsAndroid } from 'utils/operatingSystem';

const PlaidAndroidRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appRedirect, setAppRedirect] = useState({
    redirect: false,
    deepLink: 'tippy://',
    closePage: true,
    error: false,
  });

  useEffect(() => {
    redirectToApp();
    window.location.assign(appRedirect.deepLink);
  }, []);

  const redirectToApp = () => {
    const isAndroid = isOsAndroid();
    const error = !isAndroid ? true : false;
    setAppRedirect({
      redirect: true,
      deepLink: 'tippy://',
      closePage: true,
      error,
    });

    setIsLoading(false);
  };

  if (appRedirect.redirect) {
    return <OpenAppPage {...appRedirect} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return null;
};

export default PlaidAndroidRedirect;
