import React from 'react';
import { observable, action, computed, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton, Badge, Box } from '@material-ui/core';
import debounce from 'lodash/debounce';
import styles from './styles';
import StringSearchPicker from 'components/StringSearch/StringSearchPicker';
import { optionSearchFilter } from 'types';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';

import clsx from 'clsx';
import theme from 'containers/App/theme';
import PhoneInput from 'components/form/PhoneInput';

enum INPUT_IDENTIFIER {
  TEXT = 'text-search',
  PHONE = 'phone-search',
}
interface SearchBoxProps extends WithStyles<typeof styles> {
  onTextChange: (s: string) => void;
  onPhoneChange: (s: string) => void;
  onOptionSearchChange: (s: optionSearchFilter) => void;
  initialValue?: string;
  initialOptionSearchValue?: optionSearchFilter;
  debounce?: number;
  withPaper?: boolean;
  autoFocus?: boolean;
  toggleFilters?: () => void;
  phoneInput?: boolean;
  filtersActive?: boolean;
}
/**
 * A box for inputting search terms.
 */
@observer
class TextAndPhoneSearch extends React.Component<SearchBoxProps> {
  constructor(props: SearchBoxProps) {
    super(props);
    makeObservable(this);
    this.r = reaction(() => [this.search], this.handleDebounce);
  }

  static defaultProps = {
    withPaper: true,
  };

  /** The search term */
  @observable public search = this.props.initialValue || '';

  /** The phone search term */
  @observable public phoneSearch = '';

  /** The optionSearch term */
  @observable public optionSearch = this.props.initialOptionSearchValue || optionSearchFilter.NAME;

  /** Whether the search is empty */
  @computed public get searchEmpty() {
    return this.search === '';
  }

  @computed public get activeInputName() {
    return this.props.phoneInput ? INPUT_IDENTIFIER.PHONE : INPUT_IDENTIFIER.TEXT;
  }

  /** The event handler for updating the search term */
  @action.bound public updateSearch(value: string, identifier: string) {
    if (identifier === INPUT_IDENTIFIER.TEXT) {
      this.search = value;
    } else if (identifier === INPUT_IDENTIFIER.PHONE) {
      this.search = value;
    }
  }

  /** Clears the current search */
  @action.bound public clear(identifier: string) {
    if (identifier === INPUT_IDENTIFIER.TEXT) {
      this.search = '';
    } else if (identifier === INPUT_IDENTIFIER.PHONE) {
      this.phoneSearch = '';
    }
  }

  /** Inovoke proper method based on active input name */
  @action.bound public handleDebounce() {
    switch (this.activeInputName) {
      case INPUT_IDENTIFIER.TEXT:
        this.onChangeDebounced(this.search);
        break;
      case INPUT_IDENTIFIER.PHONE:
        this.onPhoneChangeDebounced(this.search);
        break;
    }
  }

  onChangeDebounced = debounce(this.props.onTextChange, this.props.debounce || 0);

  onPhoneChangeDebounced = debounce(this.props.onPhoneChange, this.props.debounce || 0);

  r;

  /** Clear the reactions */
  componentWillUnmount() {
    this.r();
  }

  renderTextSearch() {
    const { classes, autoFocus } = this.props;
    const inputComponent = this.props.phoneInput ? PhoneInput : undefined;
    return (
      <Box className={classes.textSearch}>
        <FontAwesomeIcon icon={faMagnifyingGlass} className={classes.magnifyIcon} />
        <InputBase
          className={classes.textColor}
          placeholder="Search"
          autoFocus={autoFocus}
          inputComponent={inputComponent}
          value={this.search}
          onChange={(e) => this.updateSearch(e.target.value, INPUT_IDENTIFIER.TEXT)}
          fullWidth
        />
        {!this.searchEmpty && (
          <IconButton
            disableTouchRipple
            disableRipple
            style={{ padding: 0 }}
            className={classes.noHover}
            onClick={() => this.clear(INPUT_IDENTIFIER.TEXT)}>
            <FontAwesomeIcon icon={faX} className={classes.clearIcon} />
          </IconButton>
        )}
      </Box>
    );
  }

  renderMenu() {
    const { classes, toggleFilters, filtersActive } = this.props;
    return (
      <>
        {toggleFilters && (
          <IconButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            className={clsx(classes.menu, classes.noHover)}
            onClick={toggleFilters}>
            <Badge variant="dot" color="primary" invisible={!filtersActive}>
              <FontAwesomeIcon icon={faSliders} className={classes.menuIcon} />
            </Badge>
          </IconButton>
        )}
      </>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Box display={'flex'} style={{ gap: theme.spacing(2) }} className={classes.container}>
        <Paper style={{ flexGrow: 1 }} className={classes.root}>
          {this.renderTextSearch()}
          <Divider orientation="vertical" className={classes.divider} />
          <StringSearchPicker
            onChange={(value) => {
              this.search = '';
              this.props.onOptionSearchChange(value);
            }}
          />
        </Paper>
        <Paper className={classes.paperMenu}>{this.renderMenu()}</Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(TextAndPhoneSearch);
