import { AxiosResponse } from 'axios';
import config from 'config';
import {
  Account,
  BankAccount,
  ISsoProviderResponse,
  IMFASendRequest,
  IncorporationRequest,
  Invitation,
  Location,
  LocationUser,
  NewAlertRequest,
  Pool,
  ShippingAddress,
  User,
  UserLoginResponseV2,
} from 'models';
import { ApiResponse, PagedApiResponse, RequestMetaData } from './interface';
import { createApi, makeQS } from './utils';
import { PlaidAuthLinks } from '../containers/AccountDetails/BankAttachPanel';
import { IFetchStation, IStation } from 'models/Station';
import { SettingsObject } from 'components/TipSettingsDrawer/SettingsStore';
import IMFAResult from 'models/Mfa';
import { unFormatPhoneNumber } from 'utils/helper';
import { IKycStatus } from 'models/Kyc';

const api = {
  v1: createApi({ baseURL: `${config.api.core}/v1` }),
  v2: createApi({ baseURL: `${config.api.core}/v2` }),
  v3: createApi({ baseURL: `${config.api.core}/v3` }),
};

/**
 * Logs the user in.
 * @param email The user's email
 * @param password The user's password
 */
export const login = (email: string, password: string) =>
  api.v2.post(`auth/login`, {
    email,
    password,
  });

/**
 * Logs the user in
 * @param token Token recieved from SSO provider
 */
export const loginSso = (
  email: string,
  token: string,
): Promise<AxiosResponse<ApiResponse<UserLoginResponseV2>>> =>
  api.v3.post('/auth/sso-login', { email, token });

/**
 * Refreshes the JWT token.
 */
export const refreshToken = () =>
  api.v2.get(`auth/refresh`, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

/**
 * Initiates the forgot password procedure. The backend will
 * send the user with the given email a password reset token.
 * @param email The user's email
 */
export const createPasswordResetToken = (email: string) =>
  api.v1.post(`/users/password-reset`, {
    email,
  });

/**
 * Get a password reset token to check if it's valid
 * or expired.
 */
export const getPasswordResetToken = (token: string) => api.v1.get(`users/password-reset/${token}`);

/**
 * Sends a password reset token along with the new password to change
 * the user's password
 */
export const resetPassword = (userId: number, token: string, password: string) =>
  api.v1.patch(`users/${userId}/password`, {
    password,
    token,
  });

/**
 * Initiates the forgot password procedure. The backend will
 * send the user with the given email a password reset token.
 * @param email The user's email
 */
export const resetPasswordV3 = (email: string) => api.v3.post('users/reset-password', { email });

/**
 * Sends a password reset token along with the new password to change
 * the user's password
 */
export const changePassword = (token: string, password: string) =>
  api.v3.patch(`users/change-password`, {
    password,
    token,
  });

/** Send welcome email */
export const sendWelcomeEmail = (userId: number) =>
  api.v1.post(`users/${userId}/welcome-email`, {});

/**
 * Gets the current user's accounts
 */
export const getAccounts = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
  controller?: AbortController,
) => api.v1.get(`accounts?${makeQS(rmd, extraData)}`, { signal: controller?.signal });

/**
 * Gets locations by filter
 */
export const getLocations = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`locations?${makeQS(rmd, extraData)}`);

/** Update an account */
export const updateAccount = (id: number, data: Partial<Account>) =>
  api.v1.patch(`accounts/${id}`, data);

export const searchAccounts = (query: string) => api.v1.get(`accounts/search?q=${query}`);

export const getNonOwnedAccounts = (rmd: RequestMetaData) =>
  api.v2.get(`accounts/search-non-owned?${makeQS(rmd)}`);

export const addOwnershipForAccounts = (userId: number, accountIds: number[]) =>
  api.v1.post(`accounts/add-ownership`, { userId, accountIds });

export const searchAccountLocations = (accountId: number, params?: { name?: string }) =>
  api.v1.get(`accounts/${accountId}/locations`, { params });

/* Get locations by proximity, which is defined by zip code as center and radius */
export const getLocationsByProximity = (zip: string, radius: string) => {
  const extra = { zip, radius };
  return api.v1.get(`locations/proximity?${makeQS({}, extra)}`);
};

/* Get list of users that this user has access to */
export function getUsers(
  metaData?: RequestMetaData,
  extraData?: Record<string, unknown>,
  controller?: AbortController,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users`;
  return api.v1.get(
    `${url}?${makeQS(metaData, {
      ...extraData,
      phone: unFormatPhoneNumber(extraData?.phone as string),
    })}`,
    {
      signal: controller?.signal,
    },
  );
}

/* Get list of users for tips distribution that this user has access to */
export function getDistributeTipsUsers(
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  return api.v2.get(`/users/distribute-tips?${makeQS(rmd, extraData)}`);
}

export function getAccountUsers(
  accountId: number,
  metaData?: RequestMetaData,
  extraData?: Record<string, unknown>,
  controller?: AbortController,
) {
  return api.v2.get(
    `/accounts/${accountId}/users?${makeQS(metaData, {
      ...extraData,
      phone: unFormatPhoneNumber(extraData?.phone as string),
    })}`,
    {
      signal: controller?.signal,
    },
  );
}

/**
 * Get list of users who are of type 'owners'
 */
export function getOwners(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users/owners`;
  const extraData = search ? { search, accountId, locationId } : { accountId, locationId };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/**
 * Get list of users who are of type 'managers'
 */
export function getManagers(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users/managers`;
  const extraData = search ? { search, accountId, locationId } : { accountId, locationId };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/**
 * Get list of users who are of type 'talent'
 */
export function getTalent(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users`;
  const queryParams = {
    type: 'talent',
    accountId,
    locationId,
  };
  const extraData = search ? { search, ...queryParams } : queryParams;
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/** Get employee info for user on a specific account */
export const getEmployeeInfo = (userId: number, accountId: number) => {
  const extraData = { accountId };
  return api.v1.get(`users/${userId}/employee-info?${makeQS({}, extraData)}`);
};

/**
 * Initially employee info is not set (no row), so when admin or owner first sets employeeId,
 * status is also set to 'active'. all consecutive updates are made via patch request
 */
export const createEmployeeInfo = (accountId: number, userId: number, employeeId: string) => {
  const postData = { userId, employeeId };
  return api.v1.post(`accounts/${accountId}/employee`, postData);
};

/** Update users employee info (employeeId, status) */
export const updateEmployeeInfo = (
  accountId: number,
  userId: number,
  employeeInfo: Record<string, unknown>,
) => {
  const patchData = { userId, ...employeeInfo };
  return api.v1.patch(`accounts/${accountId}/employee`, patchData);
};

/** Update location user */
export const updateLocationUser = (
  locationId: number,
  user: Partial<LocationUser>,
  controller?: AbortController,
) => api.v1.patch(`locations/${locationId}/user`, user, { signal: controller?.signal });

/** Get all locations that this user is associated with */
export const getAssociatedLocations = (userId: number) =>
  api.v1.get(`users/${userId}/associated-locations`);

/** Gets all the users associated with specific account */
export const getUsersForAccount = (
  accountId: number,
  metaData?: RequestMetaData,
  search?: string,
  type?: string,
) => {
  const url = `accounts/${accountId}/users`;
  const extraData = search ? { search, type } : { type };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
};

/** Gets all the locations for an account */
export const getAccountLocations = (accountId: number) =>
  api.v1.get(`accounts/${accountId}/locations?take=500`);

export const getAllLocationsByAccount = (accountId: number, rmd?: RequestMetaData) =>
  api.v1.get(`accounts/${accountId}/locations?${makeQS(rmd)}`);

/** Gets all the locations (admin scope only) */
export const getAllLocations = (rmd?: RequestMetaData) => {
  return api.v1.get(`locations?${makeQS(rmd)}`);
};

/** Get all locations owned by a global user */
export const getGloballyOwnedLocations = (rmd?: RequestMetaData) => {
  return api.v1.get(`globally-owned/locations?${makeQS(rmd)}`);
};

/** Searches through all the locations (admin scope only) */
export const searchAllLocations = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
  controller?: AbortController,
) => {
  return api.v1.get(`locations?${makeQS(rmd, extraData)}`, {
    signal: controller?.signal,
  });
};

/** Searches through all the unconverted locations (admin scope only) */
export const searchUnconvertedLocations = (extraData?: Record<string, unknown>) => {
  return api.v1.get(`locations/unconverted?${makeQS({}, extraData)}`);
};

/** Gets all the owners for an account */
export const getAccountOwners = (accountId: number) => api.v1.get(`accounts/${accountId}/owners`);

/** Creates a new user */
//TODO: change to v3 when MFA enabled
export const createUser = (
  email: string,
  password: string,
  extraData?: {
    accountSignup?: boolean;
    registrationToken?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  },
) =>
  api.v1.post(`users/`, {
    email,
    password,
    ...extraData,
    phone: unFormatPhoneNumber(extraData?.phone),
  });

/** Update user */
export const updateUser = (
  userId: number,
  user: Partial<User>,
): Promise<AxiosResponse<ApiResponse<User>>> => api.v3.patch(`users/${userId}`, user);

export const updateUserPhone = (
  userId: number,
  phone: string,
): Promise<AxiosResponse<ApiResponse<User>>> =>
  api.v3.post(`users/${userId}/phone`, { phone: unFormatPhoneNumber(phone) });

export const reseteUserPhone = (userId: number): Promise<AxiosResponse<ApiResponse<User>>> =>
  api.v3.patch(`users/${userId}/reset-phone`);

/** Update email */
export const updateEmail = (email: string, password?: string) =>
  api.v1.post(`users/email`, { email, password });

/** Update email */
export const updateEmailV3 = (
  email: string,
  userId: number,
): Promise<AxiosResponse<ApiResponse<User>>> => api.v3.post(`users/${userId}/email`, { email });

/** Update password */
export const updatePassword = (userId: number, currentPassword: string, newPassword: string) =>
  api.v1.put(`users/${userId}/password`, { currentPassword, newPassword });

/** Upload users profile picture  */
export const uploadProfilePicture = (
  userId: number,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
  controller?: AbortController,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v1.post(`users/${userId}/image`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    signal: controller?.signal,
    onUploadProgress,
  });
};

/** Upload users profile picture  */
export const deleteProfilePicture = (userId: number) => {
  return api.v1.delete(`users/${userId}/image`);
};

export const uploadAccountLogo = (
  accountId: number,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v2.post(`accounts/${accountId}/logo`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress,
  });
};

/** Confirm new email using token */
export const confirmEmail = (token: string) => api.v1.post(`users/email/confirm`, { token });

/** Resend email change confirmation email with token */
export const resendEmailChangeConfirmationEmail = (email?: string, confirmEmailPath?: string) =>
  api.v1.post(`users/email/resend-confirmation`, {
    email,
    confirmEmailPath,
  });

/** Send email containing invite link to a location manager */
export const sendManagerInvite = async (locationId: number, email: string) => {
  return api.v1.post(`locations/${locationId}/managers/invite`, { email });
};

/** Remove new email that is still pending for confirmation */
export const cancelEmailChange = () => api.v1.post(`users/email/cancel-change`);

/** Gets the user with the provided id */
export const getUser = (id: number) => api.v1.get(`users/${id}`);

/** Gets the devices for a user */
export const getUserDevices = (id: number) => api.v1.get(`users/${id}/devices`);

export const getUserLocationsV1 = (userId: number) => api.v1.get(`users/${userId}/locations`);

/** Get the locations for a user */
export const getUserLocationsV2 = (id: number) => api.v2.get(`users/${id}/locations`);

/** Get the accounts for a user */
export const getUserAccounts = (id: number) => api.v1.get(`users/${id}/accounts`);

/** Get all cards for a user */
export const getUserCards = (userId: number) => api.v1.get(`users/${userId}/cards`);

/** Create a new */
export const createCard = (accountNumber: string) => api.v1.post(`/cards`, { accountNumber });

/** Link an existing card */
export const linkCard = (pan: string, agreedToTermsOn: Date) =>
  api.v1.post(`/cards`, { pan, agreedToTerms: true, agreedToTermsOn });

/** Delete a card */
export const deleteCard = (cardId: number) => api.v1.delete(`/cards/${cardId}`);

/** Sets a card as a primary payout destination  */
export function makeCardPrimary(cardId: number) {
  return api.v1.post(`/cards/${cardId}/make-primary`);
}

/** enroll */
export const enroll = (ssn: string, cardId: number, agreedToTermsOn: Date) =>
  api.v1.post(`/cards/${cardId}/enroll`, { ssn, agreedToTermsOn, agreedToTerms: true });

/** Add owner for account */
// RENAME
export const addAccountOwner = (accountId: number, userId: number) =>
  api.v1.post(`accounts/${accountId}/owners`, {
    userId,
  });

/** Removes owner for account */
// RENAME
export const removeAccountOwner = (accountId: number, userId: number) =>
  api.v1.delete(`accounts/${accountId}/owners/${userId}`);

/**
 * Fetches the account with the provided id.
 * @param id The id of the account to fetch
 */
export const getAccount = (id: number) => api.v1.get(`accounts/${id}`);

/** Gets an account by code */
export function getAccountByCode(code: string): Promise<AxiosResponse<ApiResponse<Account>>> {
  return api.v1.get(`accounts/by-code/${code}`);
}

/** Post request for new account code */
export const generateNewAccountCode = (accountId: number) =>
  api.v1.post(`accounts/${accountId}/regenerate-code`);

/** Get the location */
export const getLocation = (id: number, rmd?: RequestMetaData) =>
  api.v1.get(`locations/${id}?${makeQS(rmd)}`);

/** Get locations for google map */
export const getLocationsMap = () => api.v1.get(`locations/map`);

/** Update the location */
export const updateLocation = (id: number, data: Partial<Location>) =>
  api.v1.patch(`locations/${id}`, data);

export const getLocationDormant = (id: number) => {
  return api.v1.get(`locations/${id}/dormant`);
};

export const setLocationDormant = (id: number, isDormant: boolean) => {
  return api.v1.patch(`locations/${id}/dormant`, { isDormant });
};

/** Get all users for a location */
export const getAllLocationUsers = (id: number) => api.v1.get(`locations/${id}/users`);

/** Get all devices for a location */
export const getLocationDevices = (id: number) => api.v1.get(`locations/${id}/kiosks`);

/** Get all devices for an account */
export function getAccountDevices(id: number | string, rmd?: RequestMetaData) {
  return api.v1.get(`accounts/${id}/kiosks?${makeQS(rmd)}`);
}

/** Adds the current user to the location */
export function addMeToLocation(
  locationId: number,
  accountCode: string,
  userId: number,
  controller?: AbortController,
): Promise<AxiosResponse<ApiResponse<unknown>>> {
  return api.v1.post(
    `locations/${locationId}/user`,
    {
      code: accountCode,
      userId,
    },
    { signal: controller?.signal },
  );
}

/** Adds the user with the provided id to a location */
export function addUserToLocation(userId: number, locationId: number) {
  return api.v1.post(`/locations/${locationId}/user`, {
    userId,
  });
}

/** Removes user with the provided id from the location */
export function removeUserFromLocation(userId: number, locationId: number) {
  return api.v1.delete(`/locations/${locationId}/users/${userId}`);
}

export function createBankEvent(userId: number, name: string, metadata: any) {
  return api.v1.post(`/banks/event`, {
    userId,
    name,
    metadata,
  });
}

export function createBankAccount(
  publicToken: string,
  accountId: string,
  institutionId?: string,
  identityVerificationCode?: string,
): Promise<AxiosResponse<ApiResponse<BankAccount>>> {
  return api.v2.post(`/banks`, {
    publicToken,
    accountId,
    institutionId,
    identityVerificationCode,
  });
}

export function createV3BankAccount(
  publicToken: string,
  accountId: string,
  institutionId?: string,
  identityVerificationCode?: string,
  _links?: PlaidAuthLinks,
  businessAccountId?: number,
): Promise<AxiosResponse<ApiResponse<BankAccount>>> {
  return api.v3.post(`/banks`, {
    publicToken,
    accountId,
    institutionId,
    identityVerificationCode,
    _links,
    businessAccountId,
  });
}

/** Update users bank account */
export function updateBankAccount(
  userId: number,
  fundingSource: string,
  institutionName: string,
  accountMask: string,
  accountType: string,
): Promise<AxiosResponse<ApiResponse<BankAccount>>> {
  return api.v1.post(`/users/${userId}/bank-account`, {
    fundingSource,
    institutionName,
    accountMask,
    accountType,
  });
}

/** Gets the banks for a user */
export const getUserBanks = async (userId: number) => {
  return api.v1.get(`users/${userId}/banks`);
};

export const getAccountBanks = async (accountId: number) => {
  return api.v2.get(`/accounts/${accountId}/bank-accounts`);
};

export const getUserBranchData = async (userId: number) => {
  return api.v1.get(`/users/${userId}/branch`);
};

export const updateAccountBankLocations = async (
  accountId: number,
  bankAccountId: number,
  locationIds: number[],
) => {
  const data = { bankAccountId, locationIds };
  return api.v2.patch(`/accounts/${accountId}/bank-accounts`, data);
};

export const setAccountEntityName = async (accountId: number, name: string) => {
  return api.v1.post(`/accounts/${accountId}/entity-name`, { name });
};

export const getBankAccountAuthorization = async (accountId: number) => {
  return api.v2.get(`/accounts/${accountId}/bank-accounts/on-demand-authorization`);
};

/** Gets a bank with this specific */
export function getBankAccount(bankId: number) {
  return api.v1.get(`/banks/${bankId}`);
}

/** Activates a bank account after verification */
export function activateBankAccount(bankId: number) {
  return api.v1.post(`/banks/${bankId}/activate`);
}

/** Activates a bank account after verification */
export function activateBankAccountV2(bankId: number) {
  return api.v2.post(`/banks/${bankId}/activate`);
}

/** Send email containing url link for linking account to Dwolla by bypassing Plaid */
export const sendLinkBankEmail = async (userId: number) => {
  return api.v1.post(`users/${userId}/bank-entry-link`);
};

/** Get Dwolla founding source token for linking Bank account directly with Dwolla  */
export const getDwollaToken = async (userId: number) => {
  return api.v1.post(`users/${userId}/funding-source-token`);
};

export function getUserByEmail(email: string) {
  return api.v1.get(`/users/by-email/${email}`);
}

/** Creates a new account */
export function createAccount(account: Partial<Account>) {
  return api.v1.post(`/accounts/`, account);
}

/** Get web payment processor */
export const getWebSystemSettings = () => {
  return api.v2.get(`system-settings/web`);
};

export const getSettings = (entity: SettingsObject['kind'], id: SettingsObject['id']) =>
  api.v1.get(`${entity}s/${id}/settings`);

/** Update account or location settings */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateSettings = (
  entity: 'account' | 'location' | 'station',
  id: number,
  settings: any,
) => api.v1.patch(`${entity}s/${id}/settings`, settings);

export function createLocation(location: Partial<Location> | Partial<Location>[]) {
  return api.v1.post(`/locations`, location);
}

export function deleteLocation(locationId: number) {
  return api.v1.delete(`/locations/${locationId}`);
}

export function getDashboardStats() {
  return api.v1.get(`/dashboard-stats`);
}

export function getBankEvents(userId: number) {
  return api.v1.get(`/users/${userId}/bank-events`);
}

export function getAccountBankEvents(businessAccountId: number) {
  return api.v1.get(`/accounts/${businessAccountId}/bank-events`);
}

export function makeBankAccountPrimary(bankAccountId: number) {
  return api.v1.post(`/banks/${bankAccountId}/make-primary`);
}

export function reconnectBankAccount(bankAccountId: number) {
  return api.v1.post(`/banks/${bankAccountId}/validate`);
}

export function removeBank(bankAccountId: number) {
  return api.v1.delete(`/banks/${bankAccountId}`);
}

export function linkKiosk(deviceId: number, data: { tabletId?: number; readerId?: number }) {
  return api.v1.post(`/kiosks/${deviceId}/link`, data);
}

export function getKiosks(rmd: RequestMetaData) {
  return api.v1.get(`/kiosks?${makeQS(rmd)}`);
}

export function getAccountNotes(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/notes`);
}

export function getNoteDetails(noteId: number) {
  return api.v1.get(`/notes/${noteId}`);
}

export function createNote(accountId: number, data: { topic: string; note: string }) {
  return api.v1.post(`/accounts/${accountId}/note`, data);
}

export function updateNote(noteId: number, data: { topic: string; note: string }) {
  return api.v1.patch(`/notes/${noteId}`, data);
}

export function createNoteReminder(noteId: number, dateTime: string) {
  return api.v1.post(`/notes/${noteId}/reminder`, { dateTime });
}

export function deleteNoteReminder(reminderId: number) {
  return api.v1.delete(`/reminders/${reminderId}`);
}

export function getKioskAccount(id: string) {
  return api.v1.get(`/web/accounts/${id}`);
}

export function getKioskLocation(id: string) {
  return api.v1.get(`/web/locations/${id}`);
}

export function getKioskUser(id: string) {
  return api.v1.get(`/web/users/${id}`);
}

export function getQrKioskUser(id: string) {
  return api.v1.get(`/web/qr/users/${id}`);
}

/**
 * Fetches the payout methods for an account.
 */
export function getAccountPayoutMethods(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/programs`);
}

export function getAccountIncorporationData(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/incorporation`);
}

export function updateAccountIncorporationData(accountId: number, data: IncorporationRequest) {
  return api.v1.post(`/accounts/${accountId}/incorporation`, data);
}

/** Identity verification */

export function getIdentityVerification(userId: number, code: string) {
  return api.v1.get(`/users/${userId}/identity/${code}`);
}

export function sendIdentityVerification(userId: number, destination?: 'phone' | 'email') {
  return api.v1.post(`/users/${userId}/identity`, { destination });
}

export function mergeUsers({
  sourceUserId,
  destUserId,
  deleteSource,
}: {
  sourceUserId: number;
  destUserId: number;
  deleteSource: boolean;
}) {
  return api.v1.post(`/users/merge`, {
    source: sourceUserId,
    destination: destUserId,
    deleteSource,
  });
}

export function getAlerts(rmd: RequestMetaData) {
  return api.v1.get(`/alerts?${makeQS(rmd)}`);
}

export function getActiveAlerts(rmd?: RequestMetaData) {
  return api.v1.get(`/alerts/active?${makeQS(rmd)}`);
}

export function createAlert(a: NewAlertRequest) {
  return api.v1.post(`/alerts`, a);
}

export function updateAlert(alertId: number, a: Partial<NewAlertRequest>) {
  return api.v1.patch(`/alerts/${alertId}`, a);
}

export function getAccountTalent(accountId: number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/accounts/${accountId}/talents${suffix}`);
}

export const searchLocations = (query: string) => api.v1.get(`locations/search?q=${query}`);

export function getLocationTalent(locationId: number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/locations/${locationId}/talents${suffix}`);
}

export const approveAll = {
  onLocation: (locationId: number) =>
    api.v1.post(`/locations/${locationId}/talents/approve-pending`),
  onAccount: (accountId: number) => api.v1.post(`/accounts/${accountId}/talents/approve-pending`),
};

export function getAccountInvitations(accountId: string | number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/accounts/${accountId}/invitations${suffix}`);
}

export function getLocationInvitations(locationId: number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/locations/${locationId}/invitations${suffix}`);
}

export function getUserInvitations(
  userId: string | number,
): Promise<AxiosResponse<PagedApiResponse<Invitation>>> {
  return api.v1.get(`/users/${userId}/invitations`);
}

export function inviteUsers(
  invitations: (Pick<Invitation, 'email' | 'isManager' | 'isTalent'> & {
    accountId: number;
    locationId?: number;
  })[],
) {
  return api.v1.post(`/invitations`, invitations);
}

export function revokeInvitation(id: number) {
  return api.v1.post(`/invitations/${id}/revoke`);
}

export function acceptInvitation(id: number, locationId?: number) {
  return api.v1.post(`/invitations/${id}/accept`, {
    locationId,
  });
}

export function rejectInvitation(id: number) {
  return api.v1.post(`/invitations/${id}/reject`);
}

export function createPosSystem(name: string) {
  return api.v2.post(`/pos`, { name });
}

export function getPosSystems() {
  return api.v2.get(`/pos/list`);
}

export function createAccountPos(accountId: number, posId: number) {
  return api.v2.post(`/accounts/${accountId}/pos`, { posId });
}

export function getLinkToken() {
  return api.v1.get(`/banks/link-token`);
}

export const getPoolsList = (extraData?: Record<string, unknown>) => {
  return api.v1.get(`pools?${makeQS({}, extraData)}`);
};

export const deactivatePool = (poolId: number) => {
  return api.v1.delete(`pools/${poolId}`);
};

export const activatePool = (poolId: number) => {
  return api.v1.post(`pools/${poolId}/activate`);
};

export const createPool = (pool: Pool) => {
  return api.v1.post(`/pools`, { ...pool });
};

export const updatePool = (poolId: number, pool: Pool) => {
  return api.v1.patch(`/pools/${poolId}`, { ...pool });
};

export const getGloballyOwnedUsers = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<User>>> => {
  return api.v1.get(`/globally-owned/users?${makeQS(rmd, extraData)}`);
};

export const getHasVerifiedBusinessBankAccount = (
  accountId: number,
): Promise<AxiosResponse<ApiResponse<{ hasVerifiedBusinessBankAccount: boolean }>>> => {
  return api.v1.get(`accounts/${accountId}/non-verified-banks`);
};

export function createShippingAddress(
  locationId: number,
  shippingAddress: Partial<ShippingAddress> | Partial<ShippingAddress>[],
) {
  return api.v1.post(`/locations/${locationId}/shipping-addresses`, shippingAddress);
}

export const getShippingAddress = (
  locationId: number,
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<ShippingAddress>>> => {
  return api.v1.get(`/locations/${locationId}/shipping-addresses?${makeQS(rmd, extraData)}`);
};

export const updateShippingAddress = (locationId: number, id: number, data: Partial<Location>) =>
  api.v1.patch(`/shipping-addresses/${id}`, { ...data, locationId });

export const deleteShippingAddress = (id: number) => api.v1.delete(`/shipping-addresses/${id}`);

export const getStations = (
  locationId: number,
  rmd?: RequestMetaData,
): Promise<AxiosResponse<ApiResponse<IStation[]>>> => {
  return api.v1.get(`locations/${locationId}/stations?${makeQS(rmd)}`);
};

export const getStation = (
  stationId: string,
): Promise<AxiosResponse<ApiResponse<IFetchStation>>> => {
  return api.v1.get(`/stations/${stationId}`);
};

export const createStation = (
  station: IStation,
): Promise<AxiosResponse<ApiResponse<IFetchStation>>> => {
  return api.v1.post(`stations`, station);
};

export const updateStation = (
  station: IStation,
): Promise<AxiosResponse<ApiResponse<IFetchStation>>> => {
  return api.v1.patch(`stations/${station.id}`, station);
};

export const deleteStation = (id: string): Promise<void> => {
  return api.v1.delete(`stations/${id}`);
};

/** Get Owned Accounts */
export const getUserOwnedAccounts = (userId: number) => api.v1.get(`users/${userId}/accounts`);

export const getUserSsoProvider = (
  email: string,
): Promise<AxiosResponse<ApiResponse<ISsoProviderResponse>>> => {
  return api.v1.get(`users/sso-provider?${makeQS({}, { email })}`);
};
export const sendMfaCode = (
  body: IMFASendRequest,
): Promise<AxiosResponse<ApiResponse<IMFAResult>>> => api.v3.post('users/mfa/send-code', body);

/** Get KYC status for a user */
export const getUserKycStatus = (userId: number): Promise<AxiosResponse<ApiResponse<IKycStatus>>> =>
  api.v1.get(`users/${userId}/kyc`);
/** Reset KYC attemps left for a user */
export const resetUserKycAttempsLeft = (userId: number): Promise<void> =>
  api.v3.patch(`users/${userId}/reset-kyc-attempts`);
