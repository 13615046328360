import { Theme, createStyles, makeStyles } from '@material-ui/core';
import theme from 'containers/App/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    purpleBox: {
      backgroundColor: theme.palette.secondary.main,
    },
    positive: {
      color: theme.palette.primary.main,
    },
    negative: {
      color: theme.palette.error.main,
    },
    chipConfirmed: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipPending: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    chipError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
    dialogPaper: {
      height: '700px',
    },
    dialogFormLabel: {
      color: theme.palette.secondary.main,
    },
    dialog: {
      boxShadow: 'none',
      '& .MuiPaper-root': {
        boxShadow: 'none',
      },
    },
    dialogActions: {
      paddingTop: theme.spacing(5),
      padding: theme.spacing(3),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);

export const infoBoxStyles = {
  display: 'flex',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
};
