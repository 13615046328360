import { createApi, makeQS } from './utils';
import { ApiResponse, RequestMetaData } from './interface';
import {
  Cart,
  CancellationReason,
  BillingEntity,
  Product,
  IAddToCartRequest,
  ICartItemUpdate,
} from 'models';
import config from 'config';
import { AxiosResponse } from 'axios';

const api = {
  v1: createApi({ baseURL: `${config.api.billing}/v1` }),
};

export function getPendingCarts(rmd?: RequestMetaData) {
  return api.v1.get(`/cart/pending?${makeQS(rmd)}`);
}

export function getCompletedCarts(rmd?: RequestMetaData) {
  return api.v1.get(`/sales/completed?${makeQS(rmd)}`);
}

export function getCompletedCartsById(id: string) {
  return api.v1.get(`/invoices/${id}/preview`);
}

export function getCart(accountId: number) {
  return api.v1.get(`/cart/${accountId}`);
}

export function updateCart(accountId: number, cart: Partial<Cart>) {
  return api.v1.patch(`/cart/${accountId}`, cart);
}

export function updateCartItem(accountId: number, cartItemId: number, cartItem: ICartItemUpdate) {
  return api.v1.patch(`/cart/${accountId}/item/${cartItemId}`, cartItem);
}

export function getProducts(): Promise<AxiosResponse<ApiResponse<Product[]>>> {
  return api.v1.get(`/products`);
}

export function getLocationProducts() {
  return api.v1.get('/licenses/filterable-products');
}

export const getProductsAvailableByLicense = (licenseId: number) => {
  return api.v1.get(`/licenses/${licenseId}/available-products`);
};

export const ReplaceLicenseProduct = (licenseId: number, productId: number) => {
  return api.v1.post(`/licenses/${licenseId}/replace-product`, { productId });
};

export function getProductByCode(code: string) {
  return api.v1.get(`/products/code/${code}`);
}

export function addToCart({
  accountId,
  productId,
  quantity,
  locationId,
  shippingAddressId,
  invoiceItemType,
}: IAddToCartRequest) {
  return api.v1.post(`/cart/${accountId}/item`, {
    productId,
    quantity,
    locationId,
    shippingAddressId,
    invoiceItemType,
  });
}

export function deleteFromCart(accountId: number, cartItemId: number) {
  return api.v1.delete(`/cart/${accountId}/item/${cartItemId}`);
}

export function getPromotions() {
  return api.v1.get(`/promotions?${makeQS(undefined, { includeAffiliated: false })}`);
}

export function getCartPromotions(accountId: number) {
  return api.v1.get(`/cart/${accountId}/promotions`);
}

export function getPromotionsForAffiliate(affiliateId: number) {
  return api.v1.get(`/promotions?${makeQS(undefined, { includeAffiliated: true, affiliateId })}`);
}

export function getPromotionByCode(code: string) {
  return api.v1.get(`/promotions/${code}`);
}

export function applyPromotion(accountId: number, promotionId: number) {
  return api.v1.post(`/cart/${accountId}/promotion`, {
    promotionId,
  });
}

export function unapplyPromotion(accountId: number, promotionId: number) {
  return api.v1.delete(`/cart/${accountId}/promotion/${promotionId}`);
}

export function sendPurchaseOrder(accountId: number, email: string, firstName: string) {
  return api.v1.post(`/cart/${accountId}/send-purchase-order`, { email, firstName });
}

export function finalizeCart(
  accountId: number,
  email: string,
  firstName: string,
  locationId?: number,
) {
  return api.v1.post(`/cart/${accountId}/finalize`, { email, firstName, locationId });
}

export function getCartByUuid(uuid: string) {
  return api.v1.get(`cart/${uuid}/checkout`);
}

export function pay(
  accountId: number,
  sourceId: Record<string, string>,
  expectedChargeAmount: string,
) {
  return api.v1.post(`/payments`, {
    accountId,
    expectedChargeAmount,
    ...sourceId,
  });
}

export function getLicensesForAccount(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/licenses`);
}

export function getInvoices(accountId: number) {
  return api.v1.get(`accounts/${accountId}/invoices`);
}

export function voidInvoice(invoiceId: number | string) {
  return api.v1.post(`/invoices/${invoiceId}/void`);
}

export function refundInvoice(invoiceId: number | string, amount: number, reason: string) {
  return api.v1.post(`/invoices/${invoiceId}/refund`, { amount, reason });
}

export const getPaymentMethods = (
  accountId: number,
): Promise<AxiosResponse<ApiResponse<BillingEntity[]>>> => {
  return api.v1.get(`/accounts/${accountId}/billing-entities`);
};

export const getCharges = (accountId: number) => {
  return api.v1.get(`/accounts/${accountId}/payments`);
};

export const cancelLicense = (
  licenseId: number,
  cancellationReason?: CancellationReason | string,
) => {
  return api.v1.post(`/licenses/${licenseId}/cancel`, { cancellationReason });
};

export const patchLicense = (licenseId: number, body: any) => {
  return api.v1.post(`/licenses/${licenseId}/set-billing-entity`, body);
};

export function activateLicense(licenseId: number) {
  return api.v1.post(`/licenses/${licenseId}/activate`);
}

export function extendLicense(licenseId: number, opts: { expiresAt: string }) {
  return api.v1.post(`/licenses/${licenseId}/extend`, opts);
}

export function addPaymentMethod(processorSourceId: string, accountId: number) {
  return api.v1.post(`/accounts/${accountId}/billing-entities`, {
    processorSourceId,
    accountId,
  });
}

export function removePaymentMethod(accountId: number, billingEntityId: number) {
  return api.v1.delete(`/accounts/${accountId}/billing-entities/${billingEntityId}`);
}

export const deletePendingSale = (accountId: number) => {
  return api.v1.delete(`/cart/${accountId}`);
};

export function editPaymentMethod(
  processorSourceId: string,
  accountId: number,
  billingEntityId: number,
) {
  return api.v1.patch(`/accounts/${accountId}/billing-entities/${billingEntityId}`, {
    processorSourceId,
  });
}

export function getNextPayment(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/next-payment`);
}

export function getBillingHistory(
  accountId: number,
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
) {
  return api.v1.get(`/accounts/${accountId}/billing-history?${makeQS(rmd, extraData)}`);
}

export function getLastTransaction(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/billing-history?take=1`);
}

export function getBillingHistoryStats(accountId: number, extraData?: Record<string, unknown>) {
  return api.v1.get(`/accounts/${accountId}/billing-history/stats?${makeQS({}, extraData)}`);
}

export function chargeBalance(accountId: number, paymentMethodId?: number) {
  return api.v1.post(`/accounts/${accountId}/charge`, { paymentMethodId });
}

/** Post a dollar amount correction to a specific acount */
export const createLedgerCorrection = (
  accountId: number,
  billingEntityId: number,
  amount: number,
  note: string,
) => {
  return api.v1.post(`/accounts/${accountId}/ledger-correction`, {
    billingEntityId,
    amount,
    note,
  });
};

export function updateCreditCard(accountId: number, billingEntityId: number) {
  return api.v1.post(`/accounts/${accountId}/billing-entities/${billingEntityId}/send-update-card`);
}

export function getExpiredPaymentMethods(): Promise<
  AxiosResponse<ApiResponse<BillingEntity[] | undefined>>
> {
  return api.v1.get(`/users/expired-payment-methods`);
}
