import { createApi, makeQS } from './utils';
import config from 'config';
import {
  Affiliate,
  IClaimRewardResponse,
  IReferralCreate,
  IReferralCreateResponse,
  Lead,
  LeadFormData,
} from 'models';
import { ApiResponse, RequestMetaData } from './interface';
import { AxiosResponse } from 'axios';
import { unFormatPhoneNumber } from 'utils/helper';

const api = {
  v1: createApi({ baseURL: `${config.api.marketing}/v1` }),
  v2: createApi({ baseURL: `${config.api.marketing}/v2` }),
};

/** Searches affiliates */
export function affiliateSearch(q: string) {
  const queryString = makeQS({}, { q });
  return api.v1.get(`/affiliates/search?${queryString}`);
}

export function getPromotionsForAffiliate(id: number) {
  return api.v1.get(`/affiliates/${id}/promotions`);
}

export function createAffiliate(userId: number) {
  return api.v1.post(`/affiliates`, { userId });
}

export function getAffiliateForUser(userId: number) {
  return api.v1.get(`/users/${userId}/affiliate`);
}

export function getAffiliateOrganization(code: string) {
  return api.v1.get(`/organizations?code=${code}`);
}

export function getAffiliateOrganizations(rmd?: RequestMetaData) {
  return api.v2.get(`/organizations?${makeQS(rmd)}`);
}

export function setAffiliateStatus(affiliate: Partial<Affiliate>) {
  return api.v1.patch(`/affiliates/${affiliate.id}/status`, affiliate);
}

export function updateAffiliate(affiliate: Partial<Affiliate> & Pick<Affiliate, 'id'>) {
  return api.v1.patch(`/affiliates/${affiliate.id}`, affiliate);
}

export function approveAffiliate(affiliate: Partial<Affiliate> & Pick<Affiliate, 'id'>) {
  return api.v1.patch(`/affiliates/${affiliate.id}/approve`, affiliate);
}

export const getAffiliates = (rmd?: RequestMetaData) => api.v1.get(`affiliates?${makeQS(rmd)}`);
export const getAffiliateByEmail = (email: string) => api.v1.get(`affiliates/exists/${email}`);
export const getLeads = (rmd?: RequestMetaData) => api.v1.get(`leads?${makeQS(rmd)}`);
export const postLead = (lead: Lead) => api.v1.post(`leads`, lead);
export const getConversions = (rmd?: RequestMetaData) => api.v1.get(`conversions?${makeQS(rmd)}`);

export function createConversion(args: {
  locationId: number;
  affiliateId?: number;
  promotionId?: number;
  campaignId?: number;
  leadId?: number;
  amount?: number;
}) {
  const { affiliateId, locationId, promotionId, leadId, campaignId, amount } = args;
  return api.v1.post(`/conversions/`, {
    affiliateId,
    locationId,
    promotionId,
    leadId,
    campaignId,
    amount,
  });
}

/** Set conversion status to 'counting tips' */
export const setConversionsStatusToStartCountingTips = async (ids: number[]) => {
  return api.v1.patch(`conversions/start-counting-tips`, { locations: ids });
};

/** Gets conversions belonging to specified affiliate id */
export const getAffiliateConversions = async (id: number, rmd?: RequestMetaData) => {
  return api.v1.get(`affiliates/${id}/conversions?${makeQS(rmd)}`);
};

/** Gets an affiliate's payouts */
export const getAffiliatePayouts = async (id: number) => {
  return api.v1.get(`affiliates/${id}/payouts`);
};

/** Gets report for specified affiliate id */
export const getAffiliateReports = async (id: number) => {
  return api.v1.get(`affiliates/${id}/reports`);
};

/** Get campaign by id */
export const getCampaign = async (id: number) => {
  return api.v1.get(`campaigns/${id}`);
};

/** Get campaign by code */
export const getCampaignByCode = async (code: string) => {
  return api.v1.get(`campaigns/by-code/${code}`);
};

/** Get campaign by accountId */
export const getCampaignByAccountId = async (id: number) => {
  return api.v1.get(`accounts/${id}/campaign`);
};

/** Create new lead */
export const createLead = async (data: LeadFormData) => {
  return api.v1.post(`leads`, {
    ...data,
    phone: unFormatPhoneNumber(data?.phone),
  });
};

export const getAffiliateByCode = async (code: string) => {
  return api.v1.get(`affiliates/by-code/${code}`);
};

export const getAffiliate = async (): Promise<AxiosResponse<ApiResponse<Affiliate>>> => {
  return api.v1.get(`/users/affiliate`);
};

export const generateAffiliateCode = async (
  userId: number,
): Promise<AxiosResponse<ApiResponse<Affiliate>>> => {
  return api.v1.post('/affiliates/generate-code', {
    userId,
  });
};

export const inviteReferral = async (
  request: IReferralCreate,
): Promise<AxiosResponse<ApiResponse<IReferralCreateResponse>>> => {
  return api.v1.post('/users/invite-referral', request);
};

export const checkIfAffiliate = async (
  email: string,
): Promise<AxiosResponse<ApiResponse<Affiliate>>> => {
  const queryString = makeQS({}, { email });
  return api.v1.get(`/leads/is-affiliated?${queryString}`);
};

export const claimReward = async (
  id: number,
): Promise<AxiosResponse<ApiResponse<IClaimRewardResponse>>> => {
  return api.v1.post(`/conversions/${id}/claim-reward`);
};
