import React from 'react';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Link as RouterLink } from 'react-router-dom';

import { Note } from 'models';
import moment from 'moment-timezone';

import { paths } from 'routes';
import NoteStore from './NoteStore';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import DD from 'components/DashDrawer';

import styles from './styles';
import Button from 'components/Button/Button';
import theme from 'containers/App/theme';
import { humanize } from 'utils/helper';

/** Props for this component */
interface NoteDetailsProps extends WithStyles<typeof styles> {
  noteStore: NoteStore;
}

/**
 * Displays a note drawer subcomponent containing a single note.
 */
@observer
class NoteDetails extends React.Component<NoteDetailsProps> {
  constructor(props: NoteDetailsProps) {
    super(props);
    makeObservable(this);
  }

  /** Note object */
  @computed get note(): undefined | Note {
    return this.props.noteStore.note;
  }

  render() {
    const { classes } = this.props;
    return (
      <DD>
        <DD.Content>
          <DD.Title fontSize={28} onBack={this.props.noteStore.goBack}>
            {this.note ? (
              moment(this.note.createdAt).format('MMM DD, YYYY')
            ) : (
              <Skeleton variant="text" width={190} height={50} />
            )}
          </DD.Title>

          <Box>
            {this.note && this.note.createdBy ? (
              <DD.Value>
                <Link component={RouterLink} to={paths.userDetails(this.note.createdBy.id).root()}>
                  {this.note.createdBy.firstName} {this.note.createdBy.lastName}
                </Link>
              </DD.Value>
            ) : (
              <Skeleton variant="text" width={120} />
            )}
            <DD.Label>Created by</DD.Label>
          </Box>
          {this.note && this.note.updatedBy && (
            <Box mt={3}>
              <DD.Value>
                <Link component={RouterLink} to={paths.userDetails(this.note.updatedBy.id).root()}>
                  {this.note.updatedBy.firstName} {this.note.updatedBy.lastName}
                </Link>
              </DD.Value>
              <DD.Label>Edited by</DD.Label>
            </Box>
          )}
          <Box mt={3}>
            {this.note ? (
              <DD.Value>{humanize(this.note.topic)}</DD.Value>
            ) : (
              <Skeleton variant="text" width={190} />
            )}
            <DD.Label>Topic</DD.Label>
          </Box>
          <Box mt={3}>
            {this.note ? (
              <Typography
                variant={'h6'}
                style={{
                  color: theme.palette.text.secondary,
                }}>
                {this.note.note}
              </Typography>
            ) : (
              <Box>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Box>
            )}
          </Box>
        </DD.Content>
        <DD.Actions>
          <Box mb={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => this.props.noteStore.routeTo('/reminderList')}>
              Reminders
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => this.props.noteStore.routeTo('/editNote')}>
              Edit
            </Button>
          </Box>
        </DD.Actions>
      </DD>
    );
  }
}

export default withStyles(styles)(NoteDetails);
