import React, { ReactNode } from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, IconButton, MenuItem, TextField } from '@material-ui/core';
import validatorjs from 'validatorjs';
import Api, { getErrorMsg } from 'api';
import { paths } from 'routes';

import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import * as models from 'models';
import { Address, INVOICE_ITEM_TYPES } from 'types';
import { getTimezone, setTitle } from 'services';

import AddressField from 'components/AddressField';
import CartPreview from 'components/CartPreview';
import UpDownCounter from 'components/UpDownCounter';

import SignupStore, { SignupStep } from './SignupStore';
import { ReactComponent as LocationsImg } from './locations_new.svg';

import styles from './styles';
import theme from 'containers/App/theme';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import OutlinedInput from 'components/Input/OutlinedInput';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';
import { faClose, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getShippingAddress } from 'api/core';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface FormHooks {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (form: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClear: (form: any) => void;
}

/** Here we define what kind of props this component takes */
interface LocationsStepProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    RouteComponentProps<{ accountId: string }>,
    WithUserStore, // Adds the userStore prop
    WithUiStore,
    WithToastStore {
  signupStore: SignupStore;
  nextRoute: (accountId: string | number) => string;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

/**
 * The locations screen of the account signup for admins
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class LocationsStep extends React.Component<LocationsStepProps> {
  public signupStore = this.props.signupStore!;

  public constructor(props: LocationsStepProps) {
    super(props);
    makeObservable(this);

    const fields = [
      {
        name: 'name',
        label: 'Location Name',
        rules: 'required|min:2',
      },
      {
        name: 'employeeCount',
        label: 'Number of Employees',
        rules: 'integer',
      },
      {
        name: 'address',
        label: 'Address',
        rules: 'required',
      },
      {
        name: 'shippingAddress',
        label: 'Shipping Address',
        value: null,
      },
      {
        name: 'invoiceItemType',
        label: 'Invoice Type',
        value: null,
        rules: 'required',
      },
      {
        name: 'itemProduct',
        label: 'Product',
        value: null,
        rules: 'required',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** Whether we are currently submitting */
  @observable public submitting = false;

  /** The form */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable private form: any;

  /** Whether the address is the same as the account's */
  @observable public useAccountAddress = false;

  /** Whether the address is the same as the account's */
  @observable public useAccountAddressForShipping = true;

  /** Number of kiosks */
  @observable public kioskCount = 1;

  /** Whether the owner is a talent, controlled by a checkbox */
  @observable public ownerWorksHere = false;

  /** The available products */
  @observable public products: models.Product[] = [];

  /** Edit Cart Item */
  @observable public editCartItem: models.CartItem | undefined = undefined;

  /** Filter locations by value */
  @observable public filterValue = '';

  /** Shipping address ID */
  @observable public shippingAddressId: number | undefined = undefined;

  private hooks: FormHooks = {
    onSuccess: () => {
      this.handleAddLocation();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClear: (form: any) => {
      form.clear();
    },
  };

  /** Handles the use account address checkbox */
  @action.bound public handleUseAccountAddressChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.useAccountAddress = e.target.checked;
    if (this.useAccountAddress) {
      const { address, address2, zip, city, state } = this.props.signupStore.account!;
      this.updateAddress({ address, address2, zip, city, state });
    } else {
      this.updateAddress(null);
    }
    this.form.$('address').validate();
  }

  /** Handles the use account address checkbox */
  @action.bound public handleUseAddressForShippingChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.useAccountAddressForShipping = e.target.checked;
    if (this.useAccountAddressForShipping && this.props.signupStore.account) {
      const { address, address2, zip, city, state } = this.props.signupStore.account;
      this.updateShippingAddress({ address, address2, zip, city, state });
    } else {
      this.updateShippingAddress(null);
    }
    this.form.$('shippingAddress').validate();
  }

  /** Updates the address */
  @action.bound public updateAddress(a: Address | null) {
    this.form.$('address').set(a);
  }

  /** Updates the address */
  @action.bound public updateShippingAddress(a: Address | null) {
    this.form.$('shippingAddress').set(a);
  }

  /** Sets the kiosk count */
  @action.bound public setOwnerWorksHere(value: boolean) {
    this.ownerWorksHere = value;
  }

  /** Sets the kiosk count */
  @action.bound public setKioskCount(n: number) {
    this.kioskCount = n;
  }

  /** Increases the kiosk count */
  @action.bound public incKioskCount() {
    this.setKioskCount(this.kioskCount + 1);
  }

  /** Decreases the kiosk count */
  @action.bound public decKioskCount() {
    if (this.kioskCount > 1) {
      this.setKioskCount(this.kioskCount - 1);
    }
  }

  /** Update product */
  @action.bound public updateProduct(productId: number) {
    this.form.$('itemProduct').set(productId);
  }

  /** Validate form and show the errors */
  @action.bound public validateForm() {
    this.form.validate();
    this.form.showErrors(true);
  }

  /** Resets form data and updates shipping address */
  @action.bound public updateAndResetFormFields() {
    // Clear the current form and the kiosk count
    this.clearLocation();
    // Reset same address checkbox
    this.useAccountAddress = false;
    // Update shipping address from account address
    if (this.useAccountAddressForShipping && this.props.signupStore.account) {
      const { address, address2, zip, city, state } = this.props.signupStore.account;
      this.updateShippingAddress({ address, address2, zip, city, state });
    }
    // Reset same shipping address checkbox
    this.useAccountAddressForShipping = true;
    // Set default product
    this.updateProduct(this.currentProductId);
  }

  /** Handles add new location */
  @action.bound public handleAddLocation = flow(function* (this: LocationsStep) {
    this.validateForm();

    // If form is not valid, return
    if (!this.form.isValid) {
      return;
    }

    try {
      this.submitting = true;
      // Get the timezone for the location
      const timezone: string = yield getTimezone(this.location);
      // Create the location
      yield this.props.signupStore.addLocation({
        location: { ...this.location, timezone },
        kioskCount: this.kioskCount,
        productId: this.form.$('itemProduct').value,
        invoiceItemType: this.form.$('invoiceItemType').value,
        ownerWorksHere: this.ownerWorksHere,
        shippingAddress: this.shippingAddress && {
          ...this.shippingAddress,
          default: !this.useAccountAddressForShipping,
        },
      });

      this.updateAndResetFormFields();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  /** Handles Update location */
  @action.bound public handleUpdateLocation = flow(function* (this: LocationsStep) {
    this.validateForm();

    // If form is not valid or there is no location to edit, return
    if (!this.form.isValid || !this.editCartItem?.id) {
      return;
    }

    try {
      this.submitting = true;

      // Update cart item
      yield this.props.signupStore.updateCartItem({
        itemId: this.editCartItem.id,
        cartItem: {
          location: {
            ...this.editCartItem.location,
            ...this.location,
          },
          quantity: this.kioskCount,
          invoiceItemType: this.form.$('invoiceItemType').value,
        },
        productId: this.form.$('itemProduct').value,
        oldShippingId: this.shippingAddressId,
        ownerWorksHere: this.ownerWorksHere,
        shippingAddress: {
          ...this.shippingAddress,
          default: !this.useAccountAddressForShipping,
        },
      });

      this.updateAndResetFormFields();
      this.setEditCartItem();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  /** Clears the form */
  @action.bound public clearLocation() {
    this.kioskCount = 1;
    this.setOwnerWorksHere(false);
    this.form.reset();
    this.form.clear();
  }

  /** Goes to the next step */
  @action.bound public goToNextStep = flow(function* (this: LocationsStep) {
    if (this.form.changed) {
      this.validateForm();
      if (this.form.isValid) {
        yield this.handleAddLocation();
        this.goToNextRoute();
      }
    } else {
      this.goToNextRoute();
    }
  });

  /** Initializes the component */
  @action.bound public init = flow(function* (this: LocationsStep) {
    this.props.signupStore.setStep(SignupStep.Locations);
    yield this.props.signupStore.initAccount(this.accountId);
    yield this.getProducts();

    const { account, adminSignup, affiliateSignup, cartProductId, selectedProduct } =
      this.props.signupStore;

    if (this.useAccountAddressForShipping && account) {
      const { address, address2, zip, city, state } = account;
      this.updateShippingAddress({ address, address2, zip, city, state });
    }

    // If we don't have the cart's cartProductId or a selected product,
    // go back to the previous step

    if (adminSignup && !affiliateSignup) {
      if (!cartProductId && !selectedProduct) {
        this.props.history.replace(paths.adminAccountSignup().product(this.accountId));
        return;
      }
    }

    if (this.currentProductId) {
      this.updateProduct(this.currentProductId);
    }
  });

  @action.bound public goToNextRoute() {
    this.props.history.push(this.props.nextRoute(this.accountId));
  }

  @action.bound public deleteCartItemHandler = flow(function* (
    this: LocationsStep,
    item: models.CartItem,
  ) {
    try {
      yield this.signupStore?.deleteCartItem(item);
      this.setEditCartItem();
      this.clearForm();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public getProducts = flow(function* (this: LocationsStep) {
    try {
      const { data } = yield Api.billing.getProducts();
      this.products = data?.data || [];
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public clearForm(): void {
    this.form.$('name').set('');
    this.updateAddress(null);
    this.updateShippingAddress(null);
    this.useAccountAddressForShipping = true;
    this.setKioskCount(1);
    this.setOwnerWorksHere(false);
    this.form.$('employeeCount').set('');
    this.form.$('invoiceItemType').set('');
    this.updateProduct(this.currentProductId);
  }

  @action.bound public editCartItemHandler(cartItem?: models.CartItem): void {
    this.setEditCartItem(cartItem);

    if (cartItem) {
      this.form.$('name').set(cartItem.location.name);
      this.updateAddress({
        address: cartItem.location.address,
        address2: cartItem.location.address2,
        zip: cartItem.location.zip,
        city: cartItem.location.city,
        state: cartItem.location.state,
      });
      this.setKioskCount(cartItem.quantity);
      this.form.$('employeeCount').set(cartItem.location.employeeCount || 0);
      this.form.$('invoiceItemType').set(cartItem.invoiceItemType);
      this.setOwnerWorksHere(cartItem.ownerWorksAsTalent);
      this.updateProduct(cartItem.product.id);
      this.validateForm();
    } else {
      this.clearForm();
    }
  }

  @action.bound public getShippingAddress = flow(function* (
    this: LocationsStep,
    locationId: number,
  ) {
    try {
      const { data } = yield getShippingAddress(locationId);
      const shippingAddress = data?.data?.[0];
      if (shippingAddress) {
        this.updateShippingAddress({
          address: shippingAddress.address,
          address2: shippingAddress.address2,
          zip: shippingAddress.zip,
          city: shippingAddress.city,
          state: shippingAddress.state,
        });
        this.shippingAddressId = shippingAddress.id;
        this.useAccountAddressForShipping = false;
      } else {
        this.shippingAddressId = undefined;
        this.useAccountAddressForShipping = true;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public setEditCartItem(cartItem?: models.CartItem) {
    this.editCartItem = cartItem;

    const locationId = cartItem?.location.id;
    if (locationId) {
      this.getShippingAddress(locationId);
    }
  }

  /** Sets filter value */
  @action.bound public setFilterValue(val: string) {
    this.filterValue = val.toLowerCase();
  }

  createAddressString(label: string): string {
    const addressField = this.form.$(label).value;

    if (!addressField) {
      return '';
    }

    const { address, city, state, country } = addressField;
    return [address, city, state, country || 'USA'].join(', ');
  }

  /** The address that's used for this location */
  @computed public get address(): Address | undefined {
    const addr: Address = this.useAccountAddress
      ? {
          ...(this.props.signupStore.account! as Address),
          ...this.props.signupStore.accountLatLong,
        }
      : this.form.$('address').value;

    return {
      address: addr.address,
      address2: addr.address2,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
      lat: addr.lat,
      long: addr.long,
    };
  }

  /** The address that's used for this location */
  @computed public get shippingAddress(): Address | undefined {
    if (this.useAccountAddressForShipping) {
      return undefined;
    }

    const addr: Address = this.form.$('shippingAddress').value;
    if (!addr) {
      return undefined;
    }

    return {
      address: addr.address,
      address2: addr.address2,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
    };
  }

  /** The location that's being added */
  @computed public get location(): Partial<models.Location> {
    return {
      accountId: this.props.signupStore.account!.id,
      name: this.form.$('name').value,
      employeeCount: Number(this.form.$('employeeCount').value),
      timezone: 'Unknown',
      address: this.address!.address,
      address2: this.address!.address2,
      city: this.address!.city,
      zip: this.address!.zip,
      state: this.address!.state,
      lat: this.address!.lat,
      long: this.address!.long,
    };
  }

  /** Weather to show the next button */
  @computed public get showNextButton(): boolean {
    return !!this.props.signupStore?.cart?.items.length;
  }

  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  @computed public get ownerIsTalentLabel(): string {
    return this.signupStore.selfSignup
      ? 'I work here as a service professional'
      : 'The owner works here as a talent';
  }

  @computed public get currentProductId(): number {
    const { selectedProduct, cartProductId, kioskProductId } = this.props.signupStore;
    return selectedProduct?.id || cartProductId || kioskProductId || 0;
  }

  componentDidMount() {
    if (this.signupStore.selfSignup) {
      setTitle(`Add Locations`);
    }
    this.init();
  }

  renderAddressSection(): ReactNode {
    const addressField = this.form.$('address');

    return (
      <Box pb={2}>
        <Box pb={2}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ paddingBottom: 0, paddingTop: 0 }}
                checked={this.useAccountAddress}
                onChange={this.handleUseAccountAddressChanged}
                dataCy="checkbox-location-address"
              />
            }
            label="Location address is the same as company address"
          />
        </Box>
        {this.useAccountAddress ? (
          <OutlinedInput disabled value={this.props.signupStore.accountAddress} fullWidth />
        ) : (
          <AddressField
            onChange={this.updateAddress}
            label={this.form.$('address').label}
            key={
              this.props.signupStore.locationCount /** Clear input after new location is added */
            }
            error={Boolean(addressField.error)}
            helperText={addressField.error ? addressField.error : 'Start with street number'}
            onBlur={addressField.onBlur}
            onFocus={addressField.onFocus}
            enableCustomInput
            dataCy="input-address"
            value={{ value: this.createAddressString('address') }}
          />
        )}
      </Box>
    );
  }

  renderShippingAddressSection(): ReactNode {
    const addressField = this.form.$('shippingAddress');
    return (
      <Box pb={2}>
        <FormControlLabel
          control={
            <Checkbox
              style={{ paddingBottom: 0, paddingTop: 0 }}
              checked={this.useAccountAddressForShipping}
              onChange={this.handleUseAddressForShippingChanged}
            />
          }
          label="Shipping address is the same as company address"
        />

        {!this.useAccountAddressForShipping ? (
          <Box mt={2}>
            <AddressField
              onChange={this.updateShippingAddress}
              key={this.props.signupStore.locationCount}
              error={Boolean(addressField.error)}
              label={this.form.$('shippingAddress').label}
              helperText={addressField.error ? addressField.error : 'Start with street number'}
              onBlur={addressField.onBlur}
              onFocus={addressField.onFocus}
              enableCustomInput
              InputProps={{
                disableUnderline: true,
              }}
              value={{ value: this.createAddressString('shippingAddress') }}
            />
          </Box>
        ) : null}
      </Box>
    );
  }

  renderInvoiceTypeSection(): ReactNode {
    const invoiceTypeField = this.form.$('invoiceItemType');
    return (
      <Box pb={2}>
        <OutlinedInput
          select
          label="Invoice Type"
          fullWidth
          {...this.form.$('invoiceItemType').bind()}
          dataCy="invoice-item-type-select"
          helperText={invoiceTypeField.error}
          error={Boolean(invoiceTypeField.error)}>
          {Object.entries(INVOICE_ITEM_TYPES).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </OutlinedInput>
      </Box>
    );
  }

  renderProductSection(): ReactNode {
    const itemProductField = this.form.$('itemProduct');
    return (
      <Box pb={2}>
        <OutlinedInput
          select
          label="Product"
          fullWidth
          {...this.form.$('itemProduct').bind()}
          dataCy="item-product-select"
          helperText={itemProductField.error}
          error={Boolean(itemProductField.error)}>
          {this.products.map((it) => (
            <MenuItem key={it.id} value={it.id}>
              {it.name}
            </MenuItem>
          ))}
        </OutlinedInput>
      </Box>
    );
  }

  renderKioskCount(): ReactNode {
    return (
      <Box pb={0}>
        <UpDownCounter
          label="Number of Products"
          value={this.kioskCount}
          inc={this.incKioskCount}
          dec={this.decKioskCount}
          vertical
        />
      </Box>
    );
  }

  render() {
    const { classes, signupStore } = this.props;
    if (!signupStore.accountReady) {
      return null;
    }
    return (
      <AccountSignupLayout
        title={{
          name: `Add locations to ${signupStore.account!.name}`,
          backPath: paths.adminAccountSignup().product(this.accountId),
        }}
        subtitle={{
          name: 'You can add as many locations as you wish',
        }}
        filter={
          !!signupStore.cart?.items.length && (
            <TextField
              className={classes.locationsSearch}
              placeholder="Search"
              fullWidth
              color="primary"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setFilterValue(e.target.value)
              }
              InputProps={{
                startAdornment: <FontAwesomeIcon icon={faSearch} fontSize={18} />,
                endAdornment: this.filterValue && (
                  <IconButton disableRipple onClick={() => this.setFilterValue('')}>
                    <FontAwesomeIcon icon={faClose} fontSize={18} />
                  </IconButton>
                ),
              }}
              value={this.filterValue}
            />
          )
        }
        contentLeft={
          <>
            {signupStore?.showCart && (
              <CartPreview
                editItemActive={this.editCartItem}
                editCartItem={this.editCartItemHandler}
                deleteCartItem={this.deleteCartItemHandler}
                cart={signupStore.cart!}
                cartInProgress={signupStore.cartInProgress}
                filterValue={this.filterValue}
              />
            )}
          </>
        }
        image={{ svg: <LocationsImg />, hide: signupStore?.showCart }}
        contentRight={{
          children: (
            <form
              id={'location-step-form'}
              onSubmit={this.form.onSubmit}
              className={classes.accountStepForm}>
              <Box pb={2}>
                <OutlinedInput
                  {...this.form.$('name').bind()}
                  error={Boolean(this.form.$('name').error)}
                  helperText={this.form.$('name').error}
                  fullWidth
                  autoFocus
                  dataCy="location-name-input"
                />
              </Box>
              <Box pb={2}>
                <OutlinedInput
                  {...this.form.$('employeeCount').bind()}
                  error={Boolean(this.form.$('employeeCount').error)}
                  helperText={this.form.$('employeeCount').error}
                  fullWidth
                  dataCy="location-employeeCount-input"
                  type="number"
                  className={classes.accountStepInputNumber}
                />
              </Box>
              {this.props.signupStore?.industry === models.Industry.BEAUTY && (
                <Box pb={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        checked={this.ownerWorksHere}
                        onChange={(e) => this.setOwnerWorksHere(e.target.checked)}
                        data-cy="location-address-same"
                      />
                    }
                    label={this.ownerIsTalentLabel}
                  />
                </Box>
              )}
              {this.renderAddressSection()}
              {this.renderShippingAddressSection()}
              {this.renderInvoiceTypeSection()}
              {this.renderProductSection()}
              {this.renderKioskCount()}
            </form>
          ),
          mb: 'auto',
        }}
        actionsRight={
          <Box display={'flex'} pt={2} gridGap={16} width={'100%'}>
            {this.editCartItem ? (
              // Cancel location update
              <Button
                data-cy="cancel-button"
                className={clsx(classes.locationStepButton)}
                style={{ marginTop: theme.spacing(0) }}
                color="primary"
                variant="outlined"
                onClick={() => this.editCartItemHandler()}>
                Cancel
              </Button>
            ) : (
              // Add location
              <Button
                data-cy="submit-button"
                className={clsx(classes.locationStepButton)}
                style={{ marginTop: theme.spacing(0) }}
                color="primary"
                form={'location-step-form'}
                variant={signupStore.showCart ? 'outlined' : 'contained'}
                loading={this.submitting}
                type="submit">
                Add location
              </Button>
            )}
            {this.showNextButton && (
              // Go to next step
              <Button
                className={clsx(classes.locationStepButton)}
                variant="contained"
                color="primary"
                onClick={this.editCartItem ? this.handleUpdateLocation : this.goToNextStep}
                data-cy={this.editCartItem ? 'update-button' : 'next-button'}>
                {this.editCartItem ? 'Update' : 'Next'}
              </Button>
            )}
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(LocationsStep);
