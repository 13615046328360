import theme from 'containers/App/theme';

const separatorStyling = (isEdit = false) => ({
  background: theme.palette.divider,
  content: '""',
  display: isEdit ? 'none' : 'block',
  height: 1,
  left: theme.spacing(1.5),
  position: 'absolute',
  right: theme.spacing(1.5),
});

export const cartItemStyling = (isEdit = false) => ({
  alignItems: 'flex-start',
  backgroundColor: isEdit ? theme.palette.primary.light : 'transparent',
  borderRadius: isEdit ? theme.shape.borderRadius : 0,
  display: 'flex',
  padding: theme.spacing(1.5),
  position: 'relative',
  '&::before': {
    ...separatorStyling(isEdit),
    top: 0,
  },
  '&:first-of-type': {
    '&::before': {
      display: 'none',
    },
  },
  '&:last-of-type': {
    '&::after': {
      ...separatorStyling(isEdit),
      bottom: 0,
    },
  },
  '&.--active + li': {
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .MuiTypography-root': {
    margin: theme.spacing(0.25, 0),
  },
  '& .invoice-type': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    margin: theme.spacing(0.25, 0),
  },
  '& .action-box': {
    display: 'flex',
    gridGap: 8,
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.25),
    '& .MuiButtonBase-root:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
  '& .edit-btn': {
    color: isEdit ? theme.palette.primary.main : '',
  },
});
