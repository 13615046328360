import { Observer } from 'mobx-react';
import { Box, CircularProgress, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import moment from 'moment-timezone';

import { CartItem, Cart } from 'models';
import CartItemComponent from 'components/CartItem';
import { bigProgressStyling, cartItemsContainerStyling } from './styles';
import { ReactNode, useMemo, useRef } from 'react';
import useScrollableBgr from 'hooks/useScrollableBgr';

function BigProgress() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={bigProgressStyling}>
      <CircularProgress />
    </Box>
  );
}

interface CartPreviewProps {
  cart: Cart;
  cartInProgress?: boolean;
  editItemActive?: CartItem;
  editCartItem?: (item: CartItem) => void;
  deleteCartItem?: (item: CartItem) => Promise<void>;
  filterValue?: string;
}

/**
 * Displays the cart preview of the provided cart
 */
export default function CartPreview({
  editItemActive,
  editCartItem,
  deleteCartItem,
  cartInProgress,
  cart,
  filterValue = '',
}: CartPreviewProps) {
  const middleRef = useRef<HTMLElement>(null);
  useScrollableBgr({ ref: middleRef });

  const showTaxes = cart.isTaxApplied;

  const dueOnFormat = useMemo((): string => moment(cart.dueOn).format('LL'), [cart.dueOn]);
  const totalLabel = useMemo(
    (): JSX.Element =>
      cart.payLater ? (
        <>
          <Typography
            className="item-amount"
            style={{ textTransform: 'uppercase' }}
            component="span">
            Due now
          </Typography>{' '}
          {!showTaxes && <>(before taxes)</>}
        </>
      ) : (
        <>
          <Typography
            className="item-amount"
            style={{ textTransform: 'uppercase' }}
            component="span">
            Total
          </Typography>{' '}
          {!showTaxes && <>(before taxes)</>}
        </>
      ),
    [cart.payLater, showTaxes],
  );

  const cartItems = useMemo((): ReactNode => {
    const res: CartItem[] = cart.items.filter((it) =>
      it.location.name.toLowerCase().includes(filterValue),
    );

    if (res.length) {
      return res.map((item) => (
        <CartItemComponent
          key={item.id}
          isEditActive={editItemActive?.id === item.id}
          editCartItem={editCartItem}
          deleteCartItem={deleteCartItem}
          inputValue={filterValue}>
          {item}
        </CartItemComponent>
      ));
    }

    return <Box className="no-results">There are no locations with this name.</Box>;
  }, [cart.items, deleteCartItem, editCartItem, editItemActive?.id, filterValue]);

  return (
    <Observer>
      {() => {
        // The right padding on items. If delete is disabled we don't
        // have to make room for the delete button
        const pr = deleteCartItem || editCartItem ? 8 : 0;
        if (!cart) return <></>;

        return (
          <Box {...({ ref: middleRef } as any)} sx={cartItemsContainerStyling}>
            {/* Cart Progress */}
            {cartInProgress && <BigProgress />}

            {/* Cart Empty */}
            <List disablePadding>
              {cart.items.length === 0 && (
                <Box color="text.secondary" mb={2}>
                  The cart is empty
                </Box>
              )}

              {/* Cart Items */}
              {cartItems}

              {/* Shipping */}
              {cart.totalShipping && (
                <ListItem disableGutters>
                  <ListItemText primary={<Typography component="span">SHIPPING</Typography>} />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">{cart.totalShipping}</Typography>
                  </Box>
                </ListItem>
              )}

              {/* Discount */}
              {cart.discount && cart.discount !== '$0.00' && (
                <ListItem disableGutters>
                  <ListItemText primary={<Typography component="span">DISCOUNT</Typography>} />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">-{cart.discount}</Typography>
                  </Box>
                </ListItem>
              )}

              {/* Tax */}
              {showTaxes && cart.tax && (
                <ListItem disableGutters>
                  <ListItemText primary={<Typography component="span">TAX</Typography>} />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography component="div">{cart.tax}</Typography>
                  </Box>
                </ListItem>
              )}

              {/* Gross */}
              {cart.gross && (
                <ListItem disableGutters>
                  <ListItemText primary={totalLabel} />
                  <Box justifyContent="center" alignItems="center" flexBasis="1" pr={pr}>
                    <Typography className="item-amount" component="div">
                      {cart.gross}
                    </Typography>
                  </Box>
                </ListItem>
              )}

              {/* Pay later */}
              {cart.payLater && (
                <ListItem disableGutters>
                  <Box pt={2} textAlign="center" width="100%">
                    <Typography variant="body2">
                      Next payment for {cart.payLater} due on {dueOnFormat.split(',')[0]},{' '}
                      <b>{dueOnFormat.split(',')[1]}</b>
                    </Typography>
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>
        );
      }}
    </Observer>
  );
}
