import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: 570,
    },
    actionIcon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    boxMessage: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
  });
});

export default useStyles;
