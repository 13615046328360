import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Api, { createErrorMsg, getErrorMsg } from 'api';
import clsx from 'clsx';
import DP from 'components/DashPanel';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { BankAccount } from 'models';
import React from 'react';
import * as banks from 'services/banks';
import { inject, WithModalStore, WithToastStore } from 'stores';
import { DATE_TYPE, formatLocalDateTime } from 'utils/helper';
import styles from './styles';

interface BankListItemProps extends WithStyles<typeof styles>, WithToastStore, WithModalStore {
  children: BankAccount;
  isPrimary: boolean;
  refreshBanks: () => void;
  primaryBankAccountId?: number;
}

/** Represents a single item in the BanksPanel list of banks */
@inject('toastStore', 'modalStore')
@observer
class BankListItem extends React.Component<BankListItemProps> {
  constructor(props: BankListItemProps) {
    super(props);
    makeObservable(this);
  }

  @observable public makingPrimary = false;
  @observable public reconnecting = false;
  @observable public deleting = false;

  /** Makes the bank primary for its user by doing the API call */
  @action.bound public makePrimary = flow(function* (this: BankListItem) {
    try {
      this.makingPrimary = true;
      // Make the account primary
      yield Api.core.makeBankAccountPrimary(this.props.children.id);
      // Then call the props callback to refresh the banks so that the
      // new account appears as primary
      yield this.props.refreshBanks();
      this.props.toastStore!.success(`Bank successfully set as primary!`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.makingPrimary = false;
    }
  });

  /**
   * Calls the API endpoint for reconnecting the bank account, which essentially
   * gets all the data from the dwolla and plaid again and writes that into the DB.
   */
  @action.bound public reconnect = flow(function* (this: BankListItem) {
    try {
      // Trigger a warning
      const confirmed: boolean = yield this.props.modalStore!.confirm(
        'Reconnect bank account?',
        <span>
          Doing this will fetch the bank account data from Plaid and Dwolla again. If a user is
          having issues with their bank account, this might help them.
        </span>,
      );
      if (confirmed) {
        // Call the API endpoint and display a toast notification
        const bankAccount = this.props.children;
        this.reconnecting = true;
        yield Api.core.reconnectBankAccount(bankAccount.id);
        yield this.props.refreshBanks();
        this.props.toastStore!.success(`Bank account successfully reconnected!`);
      }
    } catch (e: any) {
      this.props.modalStore!.confirm(
        `Verification failed`,
        `Unable to verify bank account at this time: ${getErrorMsg(e)}`,
        {
          hideCancel: true,
          confirmLabel: 'OK',
        },
      );
    } finally {
      this.reconnecting = false;
    }
  });

  /**
   * Calls the API endpoint for remove the bank account.
   */
  @action.bound public remove = flow(function* (this: BankListItem) {
    try {
      const bankAccount = this.props.children;
      // Trigger a warning
      const confirmed: boolean = yield this.props.modalStore!.confirm(
        'Remove bank account?',
        <span>
          Are you sure you want to remove the{' '}
          <Typography color="textPrimary">{this.institutionName}</Typography>
        </span>,
        {
          confirmLabel: <Typography color="error">Remove</Typography>,
        },
      );
      if (confirmed) {
        // Call the API endpoint and display a toast notification
        this.deleting = true;
        const resp = yield Api.core.removeBank(bankAccount.id);
        const removedBank: BankAccount = resp.data.data;

        if (removedBank.verificationStatus !== banks.BankAccountStatus.DELETED) {
          throw createErrorMsg('Bank account was not removed by unknown reason');
        }

        yield this.props.refreshBanks();
        this.props.toastStore!.success(`Bank account successfully removed!`);
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.deleting = false;
    }
  });

  @computed public get status(): banks.BankStatus {
    return banks.getStatus(this.props.children);
  }

  @computed public get isPrimary(): boolean {
    return this.props.isPrimary;
  }

  @computed public get showMakePrimary(): boolean {
    return this.isPrimary === false && this.status === 'active';
  }

  @computed public get showReconnect(): boolean {
    return true;
  }

  @computed public get showActionsMenu(): boolean {
    return true;
  }

  @computed public get loading(): boolean {
    return this.makingPrimary || this.reconnecting || this.deleting;
  }

  @computed public get institutionName(): string {
    const bank = this.props.children;
    return bank.institutionName || `Bank institution ${bank.itemId?.slice(0, 6)}`;
  }

  render() {
    const { children: bank, classes } = this.props;
    const menu = [
      { label: 'Set as primary', disabled: !this.showMakePrimary, onClick: this.makePrimary },
      { label: 'Re-verify', disabled: !this.showReconnect, onClick: this.reconnect },
      {
        label: <Typography color="error">Remove</Typography>,
        onClick: this.remove,
      },
    ];
    const icon = (
      <Avatar
        src={bank.institutionLogo ? `data:image/png;base64,${bank.institutionLogo}` : undefined}
        alt={bank.institutionName ? bank.institutionName : 'Unknown bank name'}
      />
    );
    const primaryText = <span>{this.institutionName} </span>;
    const secondaryText = (
      <Typography className={clsx(classes.secondaryText)}>
        Added on {formatLocalDateTime(bank.createdAt, DATE_TYPE.DATE)}
      </Typography>
    );

    const rightContent = (
      <Box pr={5} display="flex" flexDirection="row" alignItems="center">
        {this.props.primaryBankAccountId === bank.id && (
          <Box ml={2}>
            <Chip label="Primary" size="small" variant="outlined" color="primary" />
          </Box>
        )}
      </Box>
    );
    return (
      <DP.ListItem
        key={bank.id}
        icon={icon}
        primary={primaryText}
        secondary={secondaryText}
        rightContent={rightContent}
        menu={menu}
        loading={this.loading}
        indicator={{
          color: bank.isActive ? 'green' : 'red',
          tooltip: bank.isActive ? 'ACTIVE' : 'INACTIVE',
        }}
      />
    );
  }
}

export default withStyles(styles)(BankListItem);
