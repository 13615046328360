import { Box, Paper } from '@material-ui/core';
import { ReactNode } from 'react';
import { default as GC } from '../GraphicContainer';
import { useStyles } from './styles';
import useAccountSignunpLayout from './useAccountSignupLayout';
import { observer } from 'mobx-react';

export interface IAccountSignupLayoutProps {
  title: {
    name: string;
    backPath?: string;
  };
  subtitle: {
    name: string;
    hide?: boolean;
    mb?: number;
  };
  filter?: ReactNode;
  image?: {
    svg?: any;
    hide?: boolean;
  };
  contentLeft?: ReactNode;
  contentRight: {
    children: ReactNode;
    mb?: number | string;
  };
  actionsRight?: ReactNode;
}

const AccountSignupLayout = (props: IAccountSignupLayoutProps) => {
  const { title, subtitle, filter, image, contentLeft, contentRight, actionsRight } = props;
  const { mobileView, showSubtitle, showImage, subtitleMarginBottom, contentRightMarginBottom } =
    useAccountSignunpLayout(props);
  const { container, innerContainer, column } = useStyles({ mobileView });
  return (
    <Paper className={container}>
      <Box className={innerContainer}>
        <GC className={column}>
          <GC.Title backPath={title.backPath}>{title.name}</GC.Title>
          {showSubtitle && <GC.Subtitle mb={subtitleMarginBottom}>{subtitle.name}</GC.Subtitle>}
          {filter}
          {showImage && <GC.SvgImage>{image!.svg}</GC.SvgImage>}
          {contentLeft}
        </GC>
        <GC className={column}>
          <Box width={'100%'} height={'100%'} mb={contentRightMarginBottom}>
            {contentRight.children}
          </Box>
          {actionsRight}
        </GC>
      </Box>
    </Paper>
  );
};

export default observer(AccountSignupLayout);
