import { ListItemText, Typography, IconButton, Box } from '@material-ui/core';
import { getFullShippingAddress, getFullAddress } from 'components/CartPreview/bl';
import { CartItem } from 'models';
import { INVOICE_ITEM_TYPES } from 'types';
import { cartItemStyling } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface CartItemComponentProps {
  children: CartItem;
  isEditActive?: boolean;
  editCartItem?: (item: CartItem) => void;
  deleteCartItem?: (item: CartItem) => Promise<unknown>;
  inputValue?: string;
}

const CartItemComponent = ({
  children: item,
  isEditActive,
  editCartItem,
  deleteCartItem,
  inputValue = '',
}: CartItemComponentProps) => {
  // Get Shipping address if it exists, or Location address instead
  const fullAddress = getFullShippingAddress(item) || getFullAddress(item.location);

  const matches = match(item.location.name, inputValue, { insideWords: true });
  const parts = parse(item.location.name, matches);

  const actionButtons = useMemo((): ReactNode => {
    if (!editCartItem && !deleteCartItem) {
      return null;
    }

    return (
      <Box className="action-box">
        {editCartItem && (
          <IconButton
            size="small"
            onClick={() => editCartItem(item)}
            className="edit-btn"
            disabled={isEditActive}>
            <FontAwesomeIcon icon={faPen} fontSize={16} />
          </IconButton>
        )}

        {deleteCartItem && (
          <IconButton size="small" onClick={() => deleteCartItem(item)}>
            <FontAwesomeIcon icon={faXmarkLarge} fontSize={16} />
          </IconButton>
        )}
      </Box>
    );
  }, [deleteCartItem, editCartItem, isEditActive, item]);

  const getAutocompleteParts = useCallback(
    (parts: any): ReactNode =>
      parts.map((part: any, index: number) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
          }}>
          {part.text}
        </span>
      )),
    [],
  );

  return (
    <Box
      component={'li'}
      sx={cartItemStyling(isEditActive)}
      className={clsx(isEditActive && '--active')}>
      <ListItemText
        primary={getAutocompleteParts(parts)}
        disableTypography
        secondary={
          <>
            <Box display={'flex'} flexDirection={'column'}>
              <Box className="invoice-type">{INVOICE_ITEM_TYPES[item.invoiceItemType]}</Box>
              <Typography variant={'subtitle1'}>{fullAddress}</Typography>
              <Typography variant={'subtitle1'}>
                {`${item.quantity} ${item.product.name}`}
              </Typography>
              <Typography variant={'subtitle1'}>
                {item.location.employeeCount || 0} Employees
              </Typography>
            </Box>
          </>
        }
      />
      <Box justifyContent="center" alignItems="center" flexBasis="1">
        <Typography component="div">{item.amount}</Typography>
      </Box>

      {actionButtons}
    </Box>
  );
};

export default CartItemComponent;
