import { ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react';
import DP from 'components/DashPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { useStyles } from './styles';
import useEmailPanel from './useEmailPanel';
import Dialog from 'components/Dialog/Dialog';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import { User } from 'models';

interface EmailPanelProps {
  // We can pass user to show their email, else we will show admin's email
  user?: User;
  customUser?: boolean;
  fullWidth?: boolean;
}

const EmailPanel = observer(({ user, customUser, fullWidth = false }: EmailPanelProps) => {
  const {
    email,
    indicatorProps,
    open,
    submitting,
    submit,
    emailField,
    error,
    handleOpen,
    handleClose,
    onExited,
  } = useEmailPanel();
  const { editIcon } = useStyles();

  const renderEmail = useMemo((): ReactNode => {
    const label = <DP.Label>Email</DP.Label>;

    // User is not passed - show Admin
    if (!customUser) {
      return (
        <DP.Row>
          <DP.Value>
            {email} <DP.Indicator {...indicatorProps} />
          </DP.Value>
        </DP.Row>
      );
    }

    // User is passed but not loaded yet
    if (!user) {
      return (
        <>
          <DP.Loading items={1} />
          {label}
        </>
      );
    }

    // User is passed and loaded
    return (
      <DP.Row>
        <DP.Value>
          {user.email}{' '}
          <DP.Indicator
            color={user.isEmailConfirmed ? 'green' : 'red'}
            tooltip={user.isEmailConfirmed ? 'Email confirmed' : 'Email unconfirmed'}
          />
        </DP.Value>
        {label}
      </DP.Row>
    );
  }, [email, indicatorProps, user, customUser]);

  return (
    <DP fullWidth={fullWidth}>
      <DP.Header>
        <DP.Title panel>Email</DP.Title>
        {!customUser && (
          <DP.Actions>
            <FontAwesomeIcon onClick={handleOpen} className={editIcon} icon={faPen} />
          </DP.Actions>
        )}
      </DP.Header>
      <DP.Body>{renderEmail}</DP.Body>

      {!user && (
        <Dialog
          open={open}
          title={'Change Email'}
          loading={submitting}
          disabled={!!error}
          onConfirm={submit}
          onCancel={handleClose}
          content={
            <OutlinedInput
              fullWidth
              disabled={submitting}
              error={error}
              {...(emailField && emailField.bind())}
              value={emailField ? emailField.value : ''}
            />
          }
          TransitionProps={{
            onExited,
          }}
        />
      )}
    </DP>
  );
});

export default EmailPanel;
