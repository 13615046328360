/* eslint-disable @typescript-eslint/no-explicit-any */
import { IInvoiceItem } from 'models/Invoice';
import { CompletedCarts } from 'models';

export function getFullAddress(location?: IInvoiceItem['location']) {
  if (!location) return;
  let fullAddress = 'Address Not Available';
  const address = location.address ? `${location.address}, ` : '';
  const address2 = location?.address2 ? `${location?.address2}, ` : '';
  const city = `${location.city ? `${location.city}, ` : ''}`;
  const stateAndZip = `${location.state ? location.state : ''} ${location.zip ? location.zip : ''}${
    location.state || location.zip ? ', ' : ''
  }`;
  const country = `${location.country ? location.country : ''}`;
  fullAddress = address + address2 + city + stateAndZip + country;
  fullAddress = fullAddress.trim();
  fullAddress = fullAddress.endsWith(',') ? fullAddress.slice(0, -1) : fullAddress;
  return fullAddress;
}

// TODO remove duplicates
export function getFullShippingAddress(invoiceItem: IInvoiceItem) {
  const { shippingAddress } = invoiceItem;
  if (!shippingAddress) return;
  let fullAddress = 'Address Not Available';
  const address = `${shippingAddress.address ? `${shippingAddress.address}, ` : ''}`;
  const address2 = shippingAddress?.address2 ? `${shippingAddress?.address2}, ` : '';
  const city = `${shippingAddress.city ? `${shippingAddress.city}, ` : ''}`;
  const stateAndZip = `${shippingAddress.state ? shippingAddress.state : ''} ${
    shippingAddress.zip ? shippingAddress.zip : ''
  }${shippingAddress.state || shippingAddress.zip ? ', ' : ''}`;
  const country = `${shippingAddress.country ? shippingAddress.country : ''}`;
  fullAddress = address + address2 + city + stateAndZip + country;
  fullAddress = fullAddress.trim();
  fullAddress = fullAddress.endsWith(',') ? fullAddress.slice(0, -1) : fullAddress;
  return fullAddress;
}

export function getShippingItems(invoiceItems: CompletedCarts['invoiceItems']) {
  if (!invoiceItems) return;
  return invoiceItems.filter((item: IInvoiceItem) => item.item === 'Shipping');
}

export function getOtherItems(invoiceItems: CompletedCarts['invoiceItems']) {
  if (!invoiceItems) return;
  return invoiceItems.filter((item: IInvoiceItem) => item.item !== 'Shipping');
}

export function getShippingCost(shippingItems: CompletedCarts['invoiceItems']) {
  if (!shippingItems) return 0;
  const shippingCost: number = shippingItems.reduce(
    (totalShipping: any, invoiceItem: IInvoiceItem) => {
      if (invoiceItem.item === 'Shipping') {
        if (invoiceItem.net) {
          totalShipping = totalShipping + parseInt(invoiceItem.net);
        }
        if (invoiceItem.discount) {
          totalShipping = totalShipping + parseInt(invoiceItem.discount);
        }
        return totalShipping;
      }
    },
    0,
  );
  return shippingCost;
}

export function getItemCost(invoiceItem: IInvoiceItem) {
  if (!invoiceItem) return 0;
  let totalItemCost = 0;
  if (invoiceItem.net) {
    totalItemCost = totalItemCost + parseInt(invoiceItem.net);
  }
  if (invoiceItem.discount) {
    totalItemCost = totalItemCost + parseInt(invoiceItem.discount);
  }
  return totalItemCost;
}

export function getLocationName(invoiceItem: IInvoiceItem) {
  const locationName =
    invoiceItem.location && invoiceItem.location.name
      ? invoiceItem.location.name
      : 'Location Name Unavailable';

  return locationName;
}
