import theme from 'containers/App/theme';
import { MutableRefObject, useEffect } from 'react';

interface UseScrollableBgrProps {
  ref?: MutableRefObject<HTMLElement | null>;
  defaultBgr?: string;
  newBgr?: string;
}

const useScrollableBgr = ({
  ref,
  defaultBgr = '#fff',
  newBgr = '#fafafa',
}: UseScrollableBgrProps) => {
  useEffect(() => {
    if (!ref?.current?.scrollHeight) {
      return;
    }

    const withScrollbar = ref.current.scrollHeight > ref.current.clientHeight;

    ref.current.style.backgroundColor = withScrollbar ? newBgr : defaultBgr;
    ref.current.style.padding = withScrollbar ? `${theme.spacing(1.5)}px` : '0px';
  }, [defaultBgr, newBgr, ref, ref?.current?.scrollHeight]);
};

export default useScrollableBgr;
