import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { observable, action, computed, reaction, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { paths } from 'routes';

import { AxiosResponse } from 'axios';
import { PagedApiResponse, RequestMetaData } from 'api';
import { User } from 'models';

import { inject, WithToastStore, WithUserStore } from 'stores';

import theme from 'containers/App/theme';
import Avatar from 'components/ImageIcon';
import DP from 'components/DashPanel';

import styles from './styles';
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  ButtonBase,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import debounce from 'lodash/debounce';

/** Represents a single user in the list */
const UserListItem = observer(({ children }: { children: User; to?: string }) => {
  const user = children;
  const classes = makeStyles(styles(theme))();

  const secondaryText = `${user.email}`;
  const icon = <Avatar src={user.avatar} />;

  const menu = [
    {
      label: 'Coming soon',
      onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
      },
    },
  ];
  return (
    <ButtonBase
      className={classes.userLink}
      component={RouterLink}
      to={paths.userDetails(user.id).root()}
      disableRipple>
      <DP.ListItem
        key={user.id}
        icon={icon}
        primary={`${user.firstName} ${user.lastName}`}
        secondary={secondaryText}
        menu={menu}
      />
    </ButtonBase>
  );
});

interface UsersTabProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  fetch?: (
    meta?: RequestMetaData,
    search?: string,
  ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
  children?: User[];
  pageSize?: number;
  type?: 'talent' | 'manager' | 'owner';
}

/**
 * Displays a scrollable list of users for quick viewing. Includes a filter.
 */
@inject('toastStore', 'userStore')
@observer
class UsersTab extends React.Component<UsersTabProps> {
  constructor(props: UsersTabProps) {
    super(props);
    makeObservable(this);
    this.r = reaction(
      () => [this.skip, this.search],
      () => {
        this.onChangeDebounced();
        if (this.search === '') {
          this.onChangeDebounced.flush();
        }
      },
    );
  }

  /** The page size */
  public pageSize = this.props.pageSize || 8;

  /** The current search term */
  @observable public search = '';

  /** Whether the current users are loading */
  @observable public loading = true;

  /** The total number of results, in all pages */
  @observable public count?: number;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** How many times we've fetched the users */
  @observable public fetchCount = 0;

  /** The list of users, if we're fetching from the server */
  @observable public users: User[] = [];

  @computed private get isOwner() {
    return this.props.userStore!.scope.kind === 'owner';
  }

  // /** Updates the search term */
  // @action.bound public updateSearch(s: string) {
  //   // If we're doing server side fetching, reset all the
  //   // data since we're going to be getting a new data set
  //   // instead of just loading more from the same data set.
  //   if (this.props.fetch) {
  //     this.reset();
  //   }
  //   this.search = s;
  // }

  /** Updates the search text */
  @action.bound private updateSearch(e: React.ChangeEvent<HTMLInputElement>) {
    // If we're doing server side fetching, reset all the
    // data since we're going to be getting a new data set
    // instead of just loading more from the same data set.
    if (this.props.fetch) {
      this.reset();
    }
    this.search = e.target.value;
  }

  /** Resets the current data, used when updating the search terms with server-side fetching */
  @action.bound public reset() {
    this.skip = 0;
    this.fetchCount = 0;
    this.users = [];
    this.count = undefined;
  }
  /** Fetches the users with the current paging data */
  @action.bound public fetchUsers = flow(function* (this: UsersTab) {
    if (!this.props.fetch) {
      return;
    }
    try {
      this.loading = true;
      // Pass the pagination data and the search string (if it's not empty)
      // to the fetch function and get the response
      const resp: AxiosResponse<PagedApiResponse<User>> = yield this.props.fetch(
        {
          pagination: {
            take: this.pageSize,
            skip: this.skip,
          },
        },
        this.search !== '' ? this.search : undefined,
      );
      // If the response has data, add the new set of users to the current users
      // array and update the total count
      if (resp.data) {
        const user: any = resp.data.data;
        this.users = [...this.users, ...user];
        this.count = resp.data.count;
      }

      this.loading = false;
      // We count how many times we've fetched so we know whether the
      // user is loading more data or loading the initial set of data
      this.fetchCount = this.fetchCount + 1;
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: 'Failed to load users',
      });
    }
  });
  onChangeDebounced = debounce(this.fetchUsers, 500);

  /**
   * Loads the next page of users. There's a reaction on this.skip
   * that reruns fetchUsers every time this.skip changes
   */
  @action.bound public loadMore() {
    this.skip = this.skip + this.pageSize;
  }

  /**
   * If authenticated user is an owner and this is a tab for talents we
   * will filter inactive users by checking each returned user 'locations'
   * object if it exists and if it does check if each of its location
   * entries has locationUserStatus set to inactive and if so skip them
   */
  @action.bound public filterActiveTalents(talents: User[]) {
    return talents.filter((t) => {
      if (t.locations) {
        // Talent has no locations - an inactive talent:
        if (t.locations.length === 0) {
          return false;
        } else {
          // Talent has locations, but we need to check
          // if she is inactive on all of them
          const isInactive = (location: any) => location.locationUserStatus === 'inactive';
          return !t.locations.every(isInactive);
        }
      }
      return true;
    });
  }

  /** Whether the initial loading is happening */
  @computed public get initialLoading() {
    return this.loading && this.fetchCount === 0;
  }

  /** Whether we're loading more */
  @computed public get loadingMore() {
    return this.loading && this.fetchCount > 0;
  }

  /** The users passed via props filtered by the search */
  @computed public get filteredUsers() {
    if (this.props.children === undefined) {
      return undefined;
    }
    return this.props.children.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(this.search.toLowerCase()) ||
        (user.email && user.email.toLowerCase().includes(this.search.toLowerCase())) ||
        (user.nickname && user.nickname.toLowerCase().includes(this.search.toLowerCase())),
    );
  }

  /** Whether to display the load more button */
  @computed public get showLoadMore() {
    return this.count && this.count > this.users.length;
  }

  /** Whenever this.skip or this.search changes, fetch the users */
  r;

  /** Do the fetch on mount */
  componentDidMount() {
    this.fetchUsers();
  }

  /** Dispose of reactions on unmount */
  componentWillUnmount() {
    this.r();
  }

  /** Renders the loading skeleton */
  renderLoadingSkeleton() {
    const { loadingSkeleton } = this.props.classes;

    const singleLineSkeleton = (
      <>
        <Box display="flex" flexDirection="row" padding="8px 16px">
          <Box display="flex" alignItems="center" mr={3}>
            <Skeleton variant="rect" width={40} height={40} />
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            <Skeleton className={loadingSkeleton} height={18} width="35%" />
            <Skeleton className={loadingSkeleton} height={15} width="55%" />
          </Box>
        </Box>
        <Divider />
      </>
    );

    return (
      <DP.List height="normal">
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
        {singleLineSkeleton}
      </DP.List>
    );
  }

  /** Renders the message that there are no users to display */
  renderNoUsers() {
    return (
      <DP.List height="short">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <EmptyPanelMessage panelTitle={'users'} />
        </Box>
      </DP.List>
    );
  }

  /** Renders the message that there are no users matching search */
  renderNoMatch() {
    return (
      <DP.List height="short">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography className={this.props.classes.noUsersFoundText}>
            No users match the search criteria
          </Typography>
          ;
        </Box>
      </DP.List>
    );
  }

  /** Renders the list of users for fetch mode */
  renderWithFetch() {
    if (this.initialLoading) {
      return this.renderLoadingSkeleton();
    }
    if (this.users.length === 0) {
      return this.renderNoUsers();
    }
    const isTalentTab = this.props.type && this.props.type === 'talent';
    const users = isTalentTab && this.isOwner ? this.filterActiveTalents(this.users) : this.users;

    return (
      <Box pl={0}>
        <DP.List
          height={
            this.users.length < 3
              ? 'short'
              : this.users.length >= 3 && this.users.length <= 10
              ? 'normal'
              : 'tall'
          }>
          {users.map((user) => (
            <UserListItem key={user.id}>{user}</UserListItem>
          ))}
          {this.showLoadMore && (
            <Grid item xs={12}>
              <Box position="relative" display="flex" justifyContent="center" mt={1}>
                {/* If we're filtering paginated responses, we have no way of knowing count progress: */}
                {!(isTalentTab && this.isOwner) && (
                  <Box position="absolute" top={8} left={16}>
                    <Typography variant="subtitle2">
                      Loaded {this.users.length} out of {this.count} users
                    </Typography>
                  </Box>
                )}
                {this.loadingMore ? (
                  <CircularProgress />
                ) : (
                  <Button color="primary" size="large" onClick={this.loadMore}>
                    Load more
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </DP.List>
      </Box>
    );
  }

  /** Renders the list of users that's been passed via the children prop */
  renderLocal() {
    // Display the loading state if the users are still loading
    if (this.filteredUsers === undefined) {
      return this.renderLoadingSkeleton();
    }
    // Display a text showing no search matches if there are users
    // but no filtered users
    const noSearchMatch = this.props.children!.length > 0 && this.filteredUsers.length === 0;
    if (noSearchMatch) {
      return this.renderNoMatch();
    }
    // If there are no users, display a message
    if (this.filteredUsers.length === 0) {
      return this.renderNoUsers();
    }
    // Otherwise, display the users
    return (
      <DP.List
        height={
          this.filteredUsers.length < 3
            ? 'short'
            : this.filteredUsers.length >= 3 && this.filteredUsers.length <= 10
            ? 'normal'
            : 'tall'
        }>
        {this.filteredUsers.map((employee) => (
          <UserListItem key={employee.id}>{employee}</UserListItem>
        ))}
      </DP.List>
    );
  }

  render() {
    const { fetch } = this.props;
    return (
      <Box>
        {/* <SearchBox
          initialValue={this.search}
          onChange={this.updateSearch}
          debounce={500}
          placeholder="Name, Email or Nickname"
          withPaper={false}
        /> */}

        <Box>
          <DP.SearchInput
            value={this.search}
            onChange={this.updateSearch}
            placeholder="Name, Email or Nickname"
          />
        </Box>

        <Divider />
        <DP.TabContainer selectedTab={'talent'} tab={'talent'}>
          {!fetch ? this.renderLocal() : this.renderWithFetch()}
        </DP.TabContainer>
      </Box>
    );
  }
}

export default withStyles(styles)(UsersTab);
