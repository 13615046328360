import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import Api, { RequestMetaData } from 'api';
import { inject, WithUserStore, WithToastStore, WithSettingStore } from 'stores';
import * as models from 'models';

import { Truck, Eye } from 'mdi-material-ui';
import { Grid, Box, IconButton, Tooltip, Drawer } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { adaptForDataGridPro } from 'services';
import moment from 'moment-timezone';
import ShipmentDrawer from 'components/ShipmentDrawer';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import styles from './styles';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import InvoiceReview from 'components/InvoiceReview/InvoiceReview';
import { EDateFormat } from 'utils/helper';

interface CompletedProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithSettingStore,
    WithToastStore {}

function annotate(invoice: models.Sale) {
  return {
    id: uuidv4(),
    ...invoice,
    accountName: invoice.account && invoice.account.name,
    fulfillmentProgress: invoice.fulfillment && invoice.fulfillment.progress,
  };
}

/**
 * The container for completed sales tab.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Completed extends React.Component<CompletedProps> {
  constructor(props: CompletedProps) {
    super(props);
    makeObservable(this);
  }
  /** Whether the shipments drawer is visible */
  @observable public showShipmentDrawer = false;

  /** Sale's fulfillment ID passed to shipment drawer when activated */
  @observable public shipmentsFulfillmentId?: number;

  /** Sale's account id passed to shipment drawer */
  @observable public shipmentsAccountId?: number;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname, 'today');

  @observable public invoice?: models.CompletedCarts;

  public fetchCompletedSalesData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.billing.getCompletedCarts({
        ...rmd,
        filters: {
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      }),
    annotate,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  /** Shows the filters drawer */
  @action.bound public showShipments(fulfillmentId: number, shipmentsAccountId: number) {
    this.shipmentsFulfillmentId = fulfillmentId;
    this.shipmentsAccountId = shipmentsAccountId;
    this.showShipmentDrawer = true;
  }

  /** Hides the filters drawer */
  @action.bound public hideShipments() {
    this.showShipmentDrawer = false;
  }

  @action.bound setOpenInvoiceReview(invoice: models.CompletedCarts) {
    this.invoice = invoice;
  }

  @action.bound setCloseInvoiceReview() {
    this.invoice = undefined;
  }

  renderCellFulfillmentProgress = ({ row }: any) => {
    const { classes } = this.props;
    const totalShipments = [...(row.fulfillment?.shipments || [])].length;
    const fulfillmentProgress =
      row.fulfillmentProgress > 0 ? row.fulfillmentProgress : totalShipments;
    return (
      <LinearProgress
        sx={{
          width: '100%',
        }}
        variant="determinate"
        value={fulfillmentProgress}
        classes={{
          colorPrimary: clsx({
            [classes.linearProgressBarBackground]: totalShipments == 0,
          }),
          barColorPrimary: clsx({
            [classes.linearProgressCompled]: fulfillmentProgress == 100,
            [classes.linearProgressProcess]: fulfillmentProgress < 100,
          }),
        }}
      />
    );
  };

  renderCellLink = ({ row }: { row: models.CompletedCarts }) => {
    const rowFulfillmentId = row.fulfillment?.id;
    const rowAccountId = row.account?.id;
    return (
      <>
        {rowFulfillmentId && rowAccountId && (
          <IconButton
            color="primary"
            onClick={() => this.showShipments(rowFulfillmentId, rowAccountId)}>
            <Tooltip title="Shipments">
              <Truck fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        <IconButton color="primary" onClick={() => this.setOpenInvoiceReview(row)}>
          <Tooltip title="Invoice Review">
            <Eye fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  gridColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format(EDateFormat.DEFAULT),
    },
    { headerName: 'Account', field: 'accountName', minWidth: 200, flex: 1 },
    { headerName: 'Total', field: 'gross', minWidth: 100, flex: 1 },
    { headerName: 'Invoice #', field: 'invoiceNumber', minWidth: 150, flex: 1 },
    {
      headerName: '# of Licenses',
      field: 'licenseCount',
      minWidth: 80,
      flex: 1,
      filterable: false,
    },
    {
      headerName: 'Fulfillment Progress',
      field: 'fulfillmentProgress',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellFulfillmentProgress,
      filterable: false,
    },
    {
      headerName: 'Actions',
      field: 'link',
      minWidth: 90,
      type: 'actions',
      renderCell: this.renderCellLink,
      filterable: false,
    },
  ];

  filters: Filter[] = [{ display: 'Account', id: 'accountName', label: 'Contains', type: 'text' }];

  /** Display results using datagrid */
  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={3}>
            <FilterBar
              filters={this.filters}
              onChange={(filters: Record<string, unknown>) => {
                this.activeFilters = filters;
              }}
              externalDateRange={{
                predefined: this.dateRange,
                onChange: this.updateDateRangeValue,
              }}
            />
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.fetchCompletedSalesData}
              refetchKey={this.activeFilters}
              sortByField={'createdAt'}
              sortDirection={'DESC'}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          </Box>
          <Drawer
            open={this.showShipmentDrawer}
            onClose={this.hideShipments}
            anchor="right"
            variant="temporary">
            <ShipmentDrawer
              onClose={this.hideShipments}
              fulfillmentId={this.shipmentsFulfillmentId!}
              accountId={this.shipmentsAccountId!}
            />
          </Drawer>
          {this.invoice && (
            <InvoiceReview invoice={this.invoice} close={this.setCloseInvoiceReview} />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Completed);
