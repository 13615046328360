import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { action, flow, observable, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Bank } from 'mdi-material-ui';

import {
  inject,
  WithModalStore,
  WithPayoutSourceStore,
  WithToastStore,
  WithUserStore,
} from 'stores';
import { BankAccount, LocationUser, User } from 'models';
import { VerificationStatus } from 'types/plaid';

import Api, { ApiResponse, getErrorMsg } from 'api';
import { paths } from 'routes';
import qs from 'qs';

import TippyPlaidLink from 'components/PlaidLink';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import styles from './styles';
import { AxiosResponse } from 'axios';
import BankAccountPanel from 'components/BankAccountPanel';

import DebitCard from './DebitCard';
import BranchLogo from '../../images/branch.png';
import clsx from 'clsx';

enum DebitCardStatus {
  CREATING = 'creating',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CONDITIONAL = 'conditional',
  UPGRADED = 'upgraded',
}

export interface Card {
  id: number;
  accountBrand: string;
  cardBrand: string;
  processor: string;
  status: DebitCardStatus;
  balance: string;
  accountNumber: string;
}

interface AccountPrograms {
  ach: boolean;
  instant: boolean;
}

type PromptState = 'initial' | VerificationStatus;

interface DepositsProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    WithUserStore,
    WithPayoutSourceStore,
    RouteComponentProps {
  user?: User;
}

/** Displays the banks and cards settings for the user with :userId */
@inject('userStore', 'toastStore', 'modalStore', 'payoutSourceStore')
@observer
class Deposits extends React.Component<DepositsProps> {
  constructor(props: DepositsProps) {
    super(props);
    makeObservable(this);
  }

  /** Array of user's bank accounts || undefined if still loading || null if no accounts */
  @observable public bankAccounts?: null | BankAccount[];

  /** Array of user's cards || undefined if still loading || null if no cards */
  @observable public cards?: null | Card[];

  @observable public primaryCardId?: number;
  @observable public primaryBankAccountId?: number;

  @observable public loadingAccounts = true;
  @observable public loadingCards = true;
  @observable public loadingPrograms = true;
  @observable public loadingBranch = true;

  @observable public linkBankAccountModalOpen = false;
  @observable public creatingBankAccount = false;

  /** The current error message on create bank workflow */
  @observable public errorMsg?: string;

  /** Hide/Show Account number */
  @observable public showAccountNumber?: boolean = true;

  /** The current state of the bank prompt */
  @observable public promptState: PromptState = 'initial';

  /** Wait for all api calls and only then set loading to false */
  @computed get loading() {
    return this.loadingAccounts || this.loadingCards || this.loadingPrograms || this.loadingBranch;
  }

  @observable private branchData: any;

  @computed public get branchConnected() {
    return this.branchData && this.branchData.status;
  }

  @computed public get branchPending() {
    return this.branchConnected && this.branchData.status === 'PENDING';
  }

  @action.bound public fetchBranchData = flow(function* (this: Deposits) {
    try {
      this.loadingBranch = true;
      const resp: AxiosResponse<ApiResponse<BankAccount[]>> = yield Api.core.getUserBranchData(
        this.props.userStore!.user!.id,
      );
      this.branchData = resp.data.data;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingBranch = false;
    }
  });

  /** Prompt state for linking bank account */
  @action.bound setPromptState(verificationStatus?: string) {
    if (
      verificationStatus === 'verified' ||
      verificationStatus === 'pending_manual_verification' ||
      verificationStatus === 'pending_automatic_verification'
    ) {
      this.promptState = verificationStatus as PromptState;
    } else {
      throw new Error('An unknown error has occurred');
    }
  }

  /** Prompt state for linking bank account */
  @action.bound toggleLinkBankAccountModal() {
    this.linkBankAccountModalOpen = !this.linkBankAccountModalOpen;
  }

  /**
   * Only show cards container if user has cards or if she does not have
   * any but is eligible for realtime payments program via one of her accounts
   */
  @computed get displayCardsContainer() {
    return this.cards && !this.branchConnected;
  }

  @action.bound closePlaid(errorMsg?: string) {
    if (errorMsg) {
      this.errorMsg = errorMsg;
    }
    this.toggleLinkBankAccountModal();
  }

  @action.bound public fetchCanEnrollForNetspend = flow(function* (this: Deposits) {
    const locationUsers: LocationUser[] = this.props.userStore!.locationUsers;
    try {
      // If user is on at least one location ...
      if (locationUsers && locationUsers.length > 0) {
        // ... iterate over those locations and fetch account programs for each one
        const programsForAccounts: (AccountPrograms | undefined)[] = yield Promise.all(
          locationUsers.map(
            (user) => user.location && this.fetchAccountPrograms(user.location.accountId),
          ),
        );
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingPrograms = false;
    }
  });

  @action.bound public fetchPrimaryWallet = flow(function* (this: Deposits) {
    try {
      const user = this.props.userStore!.user!;
      const resp = yield Api.tips.getPrimaryWallet(user.id);
      const primaryWallet = resp.data.data;
      if (primaryWallet.debitCardId) {
        this.primaryCardId = primaryWallet.debitCardId;
      } else {
        this.primaryCardId = undefined;
      }
      if (primaryWallet.bankAccountId) {
        this.primaryBankAccountId = primaryWallet.bankAccountId;
      } else {
        this.primaryBankAccountId = undefined;
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Set card as primary payout destination */
  @action.bound public setCardAsPrimary = flow(function* (this: Deposits, cardId: number) {
    try {
      yield Api.core.makeCardPrimary(cardId);
      this.props.toastStore!.success('Debit card set as primary!');
      this.fetchBanks();
      this.fetchCards();
      this.fetchPrimaryWallet();
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  @action.bound public fetchBanks = flow(function* (this: Deposits) {
    try {
      const user = this.props.userStore!.user!;
      const resp: AxiosResponse<ApiResponse<BankAccount[]>> = yield Api.core.getUserBanks(user.id);
      const bankAccounts = resp.data.data;
      if (bankAccounts) this.bankAccounts = bankAccounts.length === 0 ? null : bankAccounts;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingAccounts = false;
    }
  });

  @action.bound public fetchCards = flow(function* (this: Deposits) {
    try {
      const user = this.props.userStore!.user!;
      const resp: AxiosResponse<ApiResponse<Card[]>> = yield Api.core.getUserCards(user.id);
      const cards = resp.data.data;
      if (cards) this.cards = cards.length === 0 ? null : cards;
    } catch (e: any) {
      this.cards = null;
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      // If user already has card(s) we will not be
      // checking her accounts programs for realtime payments:
      if (this.cards) this.loadingPrograms = false;
      this.loadingCards = false;
    }
  });

  /**
   * Get account programs but return the result since we need to call this function
   * dynamically when checking if any of users accounts supports realtime payments
   */
  @action.bound public fetchAccountPrograms = flow(function* (this: Deposits, accountId: number) {
    const resp: AxiosResponse<ApiResponse<AccountPrograms>> =
      yield Api.core.getAccountPayoutMethods(accountId);
    return resp.data.data;
  });

  /** Create bank account and set this bank account's verification status */
  @action.bound createBankAccount = flow(function* (
    this: Deposits,
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) {
    try {
      this.creatingBankAccount = true;

      // Create a bank account
      const resp: {
        error?: { message: string };
        bankAccount?: BankAccount;
      } = yield this.props.payoutSourceStore!.createBankAccount(publicToken, metadata);

      if (!resp) {
        throw new Error('An unknown error has occurred');
      }
      if (resp.error) {
        this.errorMsg = resp.error.message;
      }
      // Set the bank account on the component state to the returned bank account
      if (resp.bankAccount) {
        const bankAccount = resp.bankAccount as BankAccount;
        const verificationStatus = bankAccount.verificationStatus;
        verificationStatus && this.setPromptState(verificationStatus);
      }
    } catch (e: any) {
      this.errorMsg = e.message;
    } finally {
      this.fetchBanks();
      this.creatingBankAccount = false;
    }
  });

  @action.bound public deleteCard = flow(function* (this: Deposits, cardId: number) {
    try {
      const dialogTitle = 'Delete a card?';
      const dialogBody = 'Are you sure you want to delete this card?';
      const confirm = yield this.props.modalStore!.confirm(dialogTitle, dialogBody);
      if (confirm) {
        yield Api.core.deleteCard(cardId);
        this.props.toastStore!.push({ type: 'success', message: 'Card successfully deleted' });
        yield this.fetchCards();
        this.fetchCanEnrollForNetspend();
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  @action.bound public redirectToEnrollment(cardId: number) {
    const queryString = qs.stringify({ cardId });
    const path = paths.signUp().netspend().confirmAddress();
    this.props.history.push(`${path}?${queryString}`);
  }

  @action.bound refetchAll = () => {
    this.fetchBanks();
    this.fetchPrimaryWallet();
    this.fetchCards();
  };

  async componentDidMount() {
    this.fetchBranchData();
    // Get users banks and cards synchronously ...
    this.fetchBanks();
    // ... but wait for the cards response because we need to make another dependant api call
    await this.fetchCards();
    this.fetchCanEnrollForNetspend();
    this.fetchPrimaryWallet();
  }

  renderLoadingButton() {
    return (
      <Button size="large" color="primary" variant="contained" fullWidth style={{ color: 'white' }}>
        <CircularProgress color="inherit" size={24} />
      </Button>
    );
  }

  renderPlaidLink() {
    if (this.props.payoutSourceStore!.requiresIdentityVerification) {
      return (
        <Button
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          onClick={this.props.payoutSourceStore!.verifyIdentity}>
          Verify Identity
        </Button>
      );
    }
    return (
      <TippyPlaidLink
        onExit={this.closePlaid}
        onSuccess={this.createBankAccount}
        fallback={this.renderLoadingButton()}>
        <Button size="large" color="primary" variant="contained" fullWidth>
          Link Bank Account
        </Button>
      </TippyPlaidLink>
    );
  }

  renderBranchSection() {
    const classes = this.props.classes;
    return (
      <>
        {!this.branchConnected && !this.loading && (
          <Paper elevation={0}>
            <Box p={3} className={classes.branchContainer}>
              <Box className={clsx(classes.avatar)}>
                <Avatar alt="Branch Logo" src={BranchLogo} />
              </Box>

              <Box>
                <Typography className={classes.branchTitle}>Connect to Branch</Typography>
                <Typography variant="subtitle1" className={classes.branchSubTitle}>
                  Enable instant deposits through Branch credit card.
                </Typography>
              </Box>
              <Box>
                <Tooltip title={'Branch signup is currently disabled'} placement="top">
                  <span>
                    <Button
                      className={clsx(classes.button, classes.branchButton)}
                      disabled
                      // onClick={this.toggleLinkBankAccountModal}
                    >
                      ENABLE
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        )}
        {this.branchConnected &&
          !this.loading &&
          this.cards?.map((card) => (
            <Grid item key={card.id} xs={12} md={3}>
              <DebitCard
                card={card}
                isPrimary={this.primaryCardId === card.id}
                setAsPrimary={() => this.setCardAsPrimary(card.id)}
                onDelete={() => this.deleteCard(card.id)}
                onContinueEnrollment={() => this.redirectToEnrollment(card.id)}
                branch
              />
            </Grid>
          ))}
      </>
    );
  }

  renderBankAccountsSection() {
    const classes = this.props.classes;
    return (
      <>
        <Paper elevation={0}>
          <Box p={3} className={classes.branchContainer}>
            <Box className={clsx(classes.avatar)}>
              <Box className={clsx(classes.iconGreen)}>
                <Bank fontSize="large" />
              </Box>
            </Box>
            <Box>
              <Typography className={classes.branchTitle}>Add Bank Account</Typography>
              <Typography variant="subtitle1" className={classes.branchSubTitle}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Typography>
            </Box>
            <Box>
              <Button
                className={clsx(classes.button, classes.addBankButton)}
                onClick={this.toggleLinkBankAccountModal}>
                ADD
              </Button>
            </Box>
          </Box>
        </Paper>
      </>
    );
  }

  render() {
    const classes = this.props.classes;
    if (this.loading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          {this.renderBranchSection()}
        </Grid>
        {this.bankAccounts &&
          this.bankAccounts.map((bankAccount) => (
            <Grid item key={bankAccount.id} xs={12} md={3}>
              <BankAccountPanel
                fetchBankAccounts={this.refetchAll}
                isPrimary={this.primaryBankAccountId === bankAccount.id}
                onSetAsPrimary={this.refetchAll}>
                {bankAccount}
              </BankAccountPanel>
            </Grid>
          ))}
        {this.displayCardsContainer &&
          this.cards?.map((card) => (
            <Grid item key={card.id} xs={12} md={3}>
              <DebitCard
                card={card}
                isPrimary={this.primaryCardId === card.id}
                setAsPrimary={() => this.setCardAsPrimary(card.id)}
                onDelete={() => this.deleteCard(card.id)}
                onContinueEnrollment={() => this.redirectToEnrollment(card.id)}
              />
            </Grid>
          ))}
        {/* <Backdrop className={classes.backdrop} open={this.linkBankAccountModalOpen}>
          <Box className={classes.root}>
            {this.linkBankAccountModalOpen && (
              <BranchFlow
                completeBranchFlow={() => {
                  this.linkBankAccountModalOpen = false;
                  this.fetchBranchData();
                }}
                closeBranchFlow={() => {
                  this.linkBankAccountModalOpen = false;
                  this.fetchBranchData();
                }}
              />
            )}
          </Box>
        </Backdrop> */}
      </Grid>
    );
  }
}

export default withStyles(styles)(Deposits);
