/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import {
  Button,
  Box,
  Container,
  LinearProgress,
  Typography,
  Step,
  Stepper,
  StepLabel,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import * as paths from '../../routes/paths';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import { inject, WithModalStore, WithToastStore } from 'stores';
import Api from 'api';

import Layout from 'components/Layout';
import InquiryHeader from 'components/InquiryHeader';
import SalonInfoForm from 'components/SalonInfoForm';
import ConsultantForm from 'components/AffiliateEmailForm';

import SalonCentricLogo from 'images/salon_centric_logo.png';
import SummitLogo from 'images/summit_logo.png';
import AOBLogo from 'images/aob_logo.png';

import styles from './styles';
import { observable, flow, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { AxiosResponse } from 'axios';
import InquiryFooter from '../../components/InquiryFooter';
import SuccessBox from 'components/SuccessBox';
import { unFormatPhoneNumber } from 'utils/helper';

type InquiryType = 'salon' | 'summit' | 'aob';

interface InquiryFormObject {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  accountName: string;
  zip: string;
}

interface ConsultantFormObject {
  consultant: string;
  company: string;
}

interface InquiryProps extends WithStyles<typeof styles>, WithModalStore, WithToastStore {
  type: InquiryType;
}

type FormProps = InquiryFormObject & ConsultantFormObject;

interface InquiryVariables {
  headerLogo: string;
  headerColor: string;
  mainColor: string;
}

/**
 * Salon Centric inquiry screen container.
 */
@inject('modalStore', 'toastStore')
@observer
class Inquiry extends Component<InquiryProps> {
  constructor(props: InquiryProps) {
    super(props);
    makeObservable(this);
    this.inquiryType = props.type;
    this.variables = this.variablesByType;
  }

  // defined via mandatory type prop
  inquiryType: InquiryType;

  affiliateId = 0;
  salonNameForm: FormProps | any | null = null;
  salonInfoForm: FormProps | any | null = null;
  consultantForm: FormProps | any | null = null;

  steps: string[] = ['Affiliate Email', 'Salon Info', 'Success'];

  @observable public submitting = false;
  @observable public activeStep = 0;
  @observable public variables: InquiryVariables;

  @observable public shouldRedirectToAffiliateSignUp = false;

  @computed get variablesByType() {
    const type = this.inquiryType;
    if (type === 'salon') {
      return this.setVariables({
        headerLogo: SalonCentricLogo,
        headerColor: '#3ba4dd',
        mainColor: '#9ed1ee',
      });
    }
    if (type === 'summit') {
      return this.setVariables({
        headerLogo: SummitLogo,
        headerColor: '#414042',
        mainColor: '#fac60e',
      });
    }
    if (type === 'aob') {
      return this.setVariables({
        headerLogo: AOBLogo,
        headerColor: '#414042',
        mainColor: '#bbd367',
      });
    }
    return this.setVariables();
  }

  @action.bound public redirectToAffiliateSignUpModal = flow(function* (this: Inquiry) {
    const modalTitle = `Ooops, email not found`;
    const modalMessage =
      'It seems you are not an affiliate. If you want to become one, you can sign up';
    const options = {
      confirmLabel: 'Sign up',
      cancelLabel: 'No Thanks',
    };
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage, options);
    if (confirmed) {
      this.shouldRedirectToAffiliateSignUp = true;
    }
  });

  @action.bound public toastSuccess(message: string) {
    return this.props.toastStore!.success(message);
  }
  @action.bound public toastError(message: string) {
    return this.props.toastStore!.error(message);
  }

  @action.bound public postInquiry(lead: any) {
    return Api.marketing.postLead({
      ...lead,
      phone: unFormatPhoneNumber(lead?.phone),
    });
  }

  @action.bound public getAffiliateByEmail = flow(function* (this: Inquiry, email: string) {
    const resp: AxiosResponse = yield Api.marketing.getAffiliateByEmail(email);
    return resp.data.data;
  });

  @action nextStep = () => (this.activeStep += 1);
  @action setSubmitting = (value: boolean) => (this.submitting = value);
  @action setVariables = (
    variables: InquiryVariables = {
      headerLogo: '',
      headerColor: '',
      mainColor: '',
    },
  ) => {
    return (this.variables = variables);
  };

  @computed get textButton() {
    switch (this.activeStep) {
      case 0:
        return 'Next';
      case 1:
        return 'Submit';
      case 2:
        return 'Done';
      default:
        return 'Finish';
    }
  }

  onSubmit = async (e: any) => {
    e.preventDefault();
    this.setSubmitting(true);
    switch (this.activeStep) {
      case 0:
        await this.onConsultantSubmit();
        break;

      case 1:
        await this.onSalonSubmit();
        break;

      case 2:
        await this.onSuccessSubmit();
        break;
    }
    this.setSubmitting(false);
  };

  onConsultantSubmit = async () => {
    const form = this.consultantForm;
    await form.validate();
    if (!form.isValid) return form.showErrors();

    const { email } = form.values();

    try {
      const affiliate = await this.getAffiliateByEmail(email);

      if (!affiliate) return this.redirectToAffiliateSignUpModal();

      const { id: affiliateId } = affiliate;
      this.affiliateId = affiliateId;
    } catch (err: any) {
      return this.redirectToAffiliateSignUpModal();
    }

    this.nextStep();
  };

  onSalonSubmit = async () => {
    const form = this.salonInfoForm;
    await form.validate();
    if (!form.isValid) return form.showErrors();

    const lead = {
      affiliateId: this.affiliateId,
      ...this.salonInfoForm.values(),
    };

    try {
      await this.postInquiry(lead);
      this.nextStep();
    } catch (err: any) {
      this.toastError('You got an error sending your inquiry, check your form.');
    }
  };

  onSuccessSubmit = () => {
    // If you know a better way to refresh the page, let me know ;)
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    if (!this.variables.mainColor)
      return (
        <Typography variant="h3" component="h1" align="center">
          This page is not available
        </Typography>
      );

    const { headerLogo, headerColor, mainColor } = this.variables;

    if (this.shouldRedirectToAffiliateSignUp) {
      return <Redirect to={paths.affiliateSignUp()} />;
    }

    return (
      <Layout>
        <InquiryHeader
          headerLogo={headerLogo}
          headerLogoWidth={244}
          headerLogoHeight={42}
          headerColor={headerColor}
          upperBarColor={mainColor}
        />

        <Container className={classes.container}>
          <Box mt={10}>
            <Stepper activeStep={this.activeStep} alternativeLabel className={classes.stepper}>
              {this.steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <form onSubmit={this.onSubmit}>
            <Box mt={5}>
              {this.activeStep === 0 && (
                <ConsultantForm
                  getForm={(form) => (this.consultantForm = form)}
                  color={mainColor}
                />
              )}
              {this.activeStep === 1 && (
                <SalonInfoForm getForm={(form) => (this.salonInfoForm = form)} color={mainColor} />
              )}
              {this.activeStep === 2 && (
                <SuccessBox color={mainColor} text="We are excited to review your submission!" />
              )}
            </Box>
          </form>

          <Box mt={4}>
            <Button
              style={{ backgroundColor: mainColor, color: 'white' }}
              size="large"
              type="submit"
              variant="contained"
              onClick={this.onSubmit}
              disabled={this.submitting}
              fullWidth>
              {this.textButton}
            </Button>
            {this.submitting && (
              <Box pt={2}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </Container>
        <InquiryFooter />
      </Layout>
    );
  }
}

export default withStyles(styles)(Inquiry);
